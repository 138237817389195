import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography
} from "@mui/material";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import moment from "moment";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import mainUnitApi from "../../Network/MainUnit";
import Title from "./Title";
import { selectRoles } from "../../reduxStore/RolesReducer";
import AddInstallationDate from "./AddInstallationDate";

const cookies = new Cookies();



const todayStart = new Date();
const yesterday = new Date(todayStart);
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);
const todayEnd = new Date();
todayEnd.setHours(23, 59, 59, 999);

export default function Receipts() {

  const jwt = cookies.get("token");

  var headers = {
    jwt: jwt,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [openImportForm, setOpenImportForm] = useState(false)

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);




  const [filters, setFilters] = useState({
    creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
    creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
    creationDateFrom: yesterday,
    creationDateTo: todayEnd,
    name: "",
    code: "",
    type_id: null,
    wialon_id: null,
    export_excel: false,
    receipt_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
    receipt_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
    receiptDateFrom: yesterday,
    receiptDateTo: todayEnd,
    all_time_receipt_date: 1,
    all_time: 1
  })
  const [reload, setReload] = useState(false)

  const [openForm, setOpenForm] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const rolesRedux = useSelector(selectRoles)

  const columns = [
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60 },
    { field: "id", headerName: "#", minWidth: 50, maxWidth: 60 },
    { field: "receipt_number", headerName: t("UNITS.receipt_number"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "receipt_date", headerName: t("UNITS.receipt_date"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "total_units", headerName: t("UNITS.total_units"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "total_not_installed", headerName: t("UNITS.total_not_installed"), minWidth: 110, maxWidth: 150, flex: 1 },

    {
      headerName: t("UNITS.actions"),
      field: "Actions",
      flex: 0.4,
      minWidth: 100,
      renderHeader: () => (
        <Typography>
          {rolesRedux?.update_unit?.value ? t("UNITS.actions") : null}
        </Typography>
      ),
      renderCell: (rows) => (
        rolesRedux?.view_receipts?.value ?
          <DropDownGrid>
            <MenuItem onClick={() => { reserveFunction(rows?.row) }} className="ActionIcons">
              <ListItemIcon sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 7px 0 0'
              }}>
                <PendingActionsIcon style={{ fill: "#17681b" }} />
              </ListItemIcon>
              {t('UNITS.reserve')}
            </MenuItem>


          </DropDownGrid> : null
      ),
    },
  ]





  useEffect(() => {
    loadData()
  }, [pageNumber, pageSize, reload]);

  const loadData = async (searchParams) => {

    let object = {

      ...(filters?.export_excel ? {} :
        {
          page: pageNumber,
          page_size: pageSize,
        }),
      search: "",
      ...searchParams,
      ...filters
    };

    setLoading(true);

    const result = await mainUnitApi.receipts({
      params: object,
      signal: controller?.signal,
      headers: headers
    });
    setLoading(false);

    if (result.status && result?.data?.data) {


      if (filters?.export_excel) {
        setFilters({
          ...filters,
          export_excel: false
        })
        downloadExcel(result?.data?.data)
      }
      else {
        if (result?.data?.data?.data?.length > 0) {
          setRows(result?.data?.data?.data);
          setRowsTotal(result?.data?.data?.total_records);
          setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
        }
        else {
          setRows([]);
          setRowsTotal(0);
          setPageCount(0);
        }
      }
    } else {

      if (result?.data?.data?.message) {
        toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
      }
      else if (!result?.data?.error) {
        toast.error(t("NETWORK_MESSAGES.unknownError"));
        return;
      }
      if (result && result?.data && result?.data?.data?.message) {
        if (result?.data?.data?.message?.text == "unauthorized to complete") {
          navigate("/redirect");
        }
      }
    }
    setLoading(false);

  };






  const reserveFunction = (obj) => {
    setSelectedObject(obj)
    setOpenForm(true)
  }



  const clearFilterFunction = () => {
    setFilters({
      name: "",
      code: "",
      type_id: null,
      wialon_id: null,
      export_excel: false,
      receipt_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
      receipt_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
      receiptDateFrom: yesterday,
      receiptDateTo: todayEnd,
      all_time_receipt_date: 1,
      all_time: 1,
      creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
      creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
      creationDateFrom: yesterday,
      creationDateTo: todayEnd,
    })
  }
  const searchFunction = () => {
    setReload(!reload)
    setPageNumber(1)
  };

  const exportFunction = () => {

    setFilters({
      ...filters,
      export_excel: true
    })
    setReload(!reload)
  }
  const downloadExcel = async (fileName) => {
    const result = await mainUnitApi?.downloadExcel({
      params: {

        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }
  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >


      {openForm ? (
        <AddInstallationDate
          addForm={openForm}
          setAddForm={() => {
            setOpenForm(false);
            setSelectedObject(null);

          }}
          object={selectedObject}
          createMode={selectedObject ? false : true}

        />
      ) : null}

      <Title

        openFilterColumn={openFilterColumn}
      />
      <Grid
        rows={rows}
        hasExport={rolesRedux?.view_receipts?.value}
        exportFunction={exportFunction}



        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}

        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={true}



        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}
          />
        }
      />

    </Box>
  );
}
