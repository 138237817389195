import callAPI from '../ApiConfig'
import store from '../../app/store'

const mainUnits = async (props) => {

    return await callAPI({
        route: "main_units/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const mainUnitsTemp = async (props) => {

    return await callAPI({
        route: "main_units/temp",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...props?.headers
        }

    })

}
const addMainUnit = async (props) => {


    return await callAPI({
        route: "main_units/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}


const editMainUnit = async (props) => {

    return await callAPI({
        route: "main_units/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deleteMainUnit = async (props) => {


    return await callAPI({
        route: "main_units/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getMainUnitList = async (props) => {

    return await callAPI({
        route: "main_units/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: {

        }

    })

}

const refreshDataFromWialon = async (props) => {

    return await callAPI({
        route: "main_units/refresh_data_from_wialon",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...props?.headers
        }

    })

}

const downloadExcel = async (props) => {


    return await callAPI({
        route: "main_units/download",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params
        },
        headers: {

        },
        responseType: "blob"

    })
}

const uploadPoints = async (props) => {

    let formdata = new FormData();

    formdata.append("files", props?.data?.file)

    return await callAPI({
        route: "main_units/import",
        method: "post",
        signal: props?.signal,
        data: formdata,
        params: props?.params,
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            ...props?.headers
        }
    })
}

const editMainUnitTemp = async (props) => {

    return await callAPI({
        route: "main_units/temp/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}

const addMainUnitTemp = async (props) => {


    return await callAPI({
        route: "main_units/temp/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}

const reportForMainUnits = async (props) => {

    return await callAPI({
        route: "main_units/report",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
        },
        headers: {

        }

    })

}

const clearAll = async (props) => {

    return await callAPI({
        route: "main_units/clear_all",
        method: "delete",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}

const linktMainUnitTemp = async (props) => {

    return await callAPI({
        route: "main_units/temp/link/"+props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const bulkUpdate = async (props) => {


    return await callAPI({
        route: "main_units/temp/bulk_update",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const mainUnitLogs = async (props) => {

    return await callAPI({
        route: "main_units/logs",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...props?.headers
        }

    })

}

const editPlateMainUnitTemp = async (props) => {

    return await callAPI({
        route: "main_units/temp/update_plate/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}

const mainUnitActivities = async (props) => {

    return await callAPI({
        route: "main_units/activities",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...props?.headers
        }

    })

}
const receipts = async (props) => {

    return await callAPI({
        route: "main_units/receipts",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...props?.headers
        }

    })
}
const mainUnitsWithReservations = async (props) => {

    return await callAPI({
        route: "main_units/main_units_with_reservations",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...props?.headers
        }

    })
}
const addBulkReservation = async (props) => { 
    return await callAPI({
        route: "main_units/bulk_insert_reservations",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const getCount = async (props) => { 
    return await callAPI({
        route: "main_units/reservation_by_date",
        method: "get",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...props?.headers
        }
    })
 }
const mainUnitAPI = {
    mainUnits: mainUnits,
    addMainUnit: addMainUnit,
    editMainUnit: editMainUnit,
    deleteMainUnit: deleteMainUnit,
    getMainUnitList: getMainUnitList,
    refreshDataFromWialon: refreshDataFromWialon,
    downloadExcel: downloadExcel,
    uploadPoints: uploadPoints,
    mainUnitsTemp: mainUnitsTemp,
    editMainUnitTemp: editMainUnitTemp,
    addMainUnitTemp: addMainUnitTemp,
    clearAll: clearAll,
    reportForMainUnits: reportForMainUnits,
    linktMainUnitTemp: linktMainUnitTemp,
    bulkUpdate: bulkUpdate,
    mainUnitLogs: mainUnitLogs,
    editPlateMainUnitTemp: editPlateMainUnitTemp,
    mainUnitActivities: mainUnitActivities,
    receipts: receipts,
    mainUnitsWithReservations: mainUnitsWithReservations,
    addBulkReservation: addBulkReservation,
    getCount: getCount

}
export default mainUnitAPI