import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { toast } from "react-toastify";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DropDownGrid from "../Grid/Components/DropDownGrid";
import { SiMicrosoftexcel } from 'react-icons/si'
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography,
  Tooltip
} from "@mui/material";
import swal from "sweetalert";
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import mainUnitApi from "../../Network/MainUnit";
import FormContainer from "./Form/FormContainer";
import Title from "./Title";
import { selectRoles } from "../../reduxStore/RolesReducer";
import plateLetterAPI from "../../Network/PlateLetter";
import plateTypeAPI from "../../Network/PlateType";
import plateProvinceAPI from "../../Network/PlateProvince";
import userAPI from "../../Network/User";
import ImportFormContainer from "./ImportForm/ImportFormContainer";
import moment from "moment";
import WialonLinksFormContainer from "./WialonLinks/WialonLinksContainer";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MainUnitLogs from "./MainUnitLogs/MainUnitLogs";
import BookIcon from '@mui/icons-material/Book';
import ViewFormContainer from "./ViewForm/ViewFormContainer";
import FullUpdateFormContainer from "./FullUpdateForm/FullUpdateFormContainer";
import MainUnitActivities from "./MainUnitActivities/MainUnitActivities";
import helpers from "../../assets/js/helper";

const cookies = new Cookies();


const todayStart = new Date();
const yesterday = new Date(todayStart);
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(0, 0, 0, 0);
const todayEnd = new Date();
todayEnd.setHours(23, 59, 59, 999);
export default function MainUnitsTemp() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [showLogs, setShowLogs] = useState(false)
  const [showActivities, setShowActivities] = useState(false)
  const [listOfSelectedRows, setListOfSelectedRows] = useState([])

  const [selectionRows, setSelectionRows] = useState([])
  const [selectionModel, setSelectionModel] = useState([])

  const [openImportForm, setOpenImportForm] = useState(false)
  const [openWialonLinkForm, setOpenWialonLinkForm] = useState(false)

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);

  const [plateTypes, setPlateTypes] = useState([])
  const [loadingPlateTypes, setLoadingPlateTypes] = useState(true)

  const [plateProvinces, setPlateProvinces] = useState([])
  const [loadingPlateProvinces, setLoadingPlateProvinces] = useState(true)

  const [plateLetters, setPlateLetters] = useState([])
  const [loadingPlateLetters, setLoadingPlateLetters] = useState(true)
  const [wialonUnits, setWialonUnits] = useState([])
  const [users, setUsers] = useState([])
  const [loadingUsers, setLoadingUsers] = useState(true)

  const [filters, setFilters] = useState({
    creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
    creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
    creationDateFrom: yesterday,
    creationDateTo: todayEnd,
    plate_number: "",
    receipt_number: "",
    company_name: "",
    is_subscribed: -1,
    is_installed_custom: -1,
    is_installed: -1,
    update_note: "",
    export_excel: false,
    export_special_excel: false,
    receipt_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
    receipt_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
    receiptDateFrom: yesterday,
    receiptDateTo: todayEnd,
    all_time_receipt_date: 1,
    all_time: 1,
    active: -1
  })
  const [reload, setReload] = useState(false)

  const [openForm, setOpenForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);
  const [openFullUpdateForm, setOpenFullUpdateForm] = useState(false)

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [openViewForm, setOpenViewForm] = useState(false);
  const rolesRedux = useSelector(selectRoles)

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 100,
    },
    { field: "index_column", headerName: "#", minWidth: 50, maxWidth: 60 },

    { field: "receipt_number", headerName: t("UNITS.receipt_number"), minWidth: 110, maxWidth: 150 },
    { field: "numbering", headerName: "ت", minWidth: 50, maxWidth: 60 },
    { field: "receipt_date", headerName: t("UNITS.receipt_date"), minWidth: 110, maxWidth: 150 },
    { field: "tid", headerName: t("UNITS.tid"), minWidth: 110, maxWidth: 150, flex: 2, hide: !(cookies.get('id') == 46) },
    { field: "epc", headerName: t("UNITS.epc"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "company_name", headerName: t("UNITS.company_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "original_customer", headerName: t("UNITS.original_customer"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "driver_name", headerName: t("UNITS.driver_name"), minWidth: 110, maxWidth: 150, flex: 2 },
    { field: "vin_number", headerName: t("UNITS.vin"), minWidth: 210, maxWidth: 250, flex: 2 },
    { field: "plate_number", headerName: t("UNITS.plate_number"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "plate_letter", headerName: t("UNITS.plate_letter"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "plate_type", headerName: t("UNITS.plate_type"), minWidth: 110, maxWidth: 150, flex: 1 },
    // { field: "plate_letter_name", headerName: t("UNITS.plate_letter"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "plate_province", headerName: t("UNITS.plate_province"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "name_and_type", headerName: t("UNITS.name_and_type"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "wialon_name", headerName: t("UNITS.wialon_name"), minWidth: 150, maxWidth: 180, flex: 1 },
    { field: "account_name", headerName: t("UNITS.account_name"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "note", headerName: t("UNITS.note"), minWidth: 110, maxWidth: 150, flex: 1 },

    { field: "installation_date_formatted", headerName: t("UNITS.installation_date"), minWidth: 110, maxWidth: 150, flex: 1 },
    { field: "first_payment_date", headerName: t("UNITS.first_payment_date"), minWidth: 110, maxWidth: 150, flex: 2, hide: !(cookies.get('id') == 46) },
    { field: "last_payment_date_formatted", headerName: t("UNITS.last_payment_date_formatted"), minWidth: 110, maxWidth: 150, flex: 2, hide: !(cookies.get('id') == 46) },
    { field: "days_left_to_expire", headerName: t("UNITS.days_left_to_expire"), minWidth: 110, maxWidth: 150, flex: 1, hide: !(cookies.get('id') == 46) },
    {
      field: "is_installed", headerName: t("UNITS.is_installed"), minWidth: 110, maxWidth: 150, flex: 1,
      renderCell: (rows) => (
        <>
          {rows?.row?.is_installed == 1 ? (
            <MenuItem
              onClick={() => {

              }}
              className="ActionIcons"
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px 7px 0 0",
                }}
              >
                <CheckCircleIcon style={{ fill: "#388e3c" }} />
              </ListItemIcon>

            </MenuItem>
          ) : <MenuItem
            onClick={() => {

            }}
            className="ActionIcons"
          >
            <Tooltip
              title={
                <span style={{ fontFamily: "Cairo-Medium" }}>
                  {rows?.row?.update_note}
                </span>
              }
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px 7px 0 0",
                }}
              >
                <DoDisturbOnIcon style={{ fill: "#d32f2f" }} />
              </ListItemIcon>
            </Tooltip>

          </MenuItem>}
        </>
      )
    },
    {
      field: "is_subscribed", headerName: t("UNITS.is_subscribed"), minWidth: 110, maxWidth: 150, flex: 1,
      renderCell: (rows) => (
        <>
          {rows?.row?.is_subscribed ? (
            <MenuItem
              onClick={() => {

              }}
              className="ActionIcons"
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px 7px 0 0",
                }}
              >
                <CheckCircleIcon style={{ fill: "#388e3c" }} />
              </ListItemIcon>

            </MenuItem>
          ) : <MenuItem
            onClick={() => {

            }}
            className="ActionIcons"
          >
            <ListItemIcon
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "0px 7px 0 0",
              }}
            >
              <DoDisturbOnIcon style={{ fill: "#d32f2f" }} />
            </ListItemIcon>


          </MenuItem>}
        </>
      )
    },
    {
      headerName: t("UNITS.actions"),
      field: "Actions",
      flex: 0.4,
      minWidth: 100,
      renderHeader: () => (
        <Typography>
          {rolesRedux?.update_unit?.value ? t("UNITS.actions") : null}
        </Typography>
      ),
      renderCell: (rows) => (
        rolesRedux?.update_unit?.value || rolesRedux?.view_main_unit?.value ?
          <DropDownGrid>
            {rolesRedux?.view_main_unit?.value ?
              <MenuItem onClick={() => {
                viewFunction(rows?.row)
              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <VisibilityIcon style={{ fill: "#17681b" }} />

                </ListItemIcon>
                {t('UNITS.view_title')}
              </MenuItem> : null}
            <MenuItem onClick={() => { showLogsFunction(rows?.row) }} className="ActionIcons">
              <ListItemIcon sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 7px 0 0'
              }}>
                <BookIcon style={{ fill: "#17681b" }} />
              </ListItemIcon>
              {t('UNITS.show_logs')}
            </MenuItem>
            {rolesRedux?.show_main_units_activites?.value ?
              <MenuItem onClick={() => { showActivitiesFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <BookIcon style={{ fill: "#17681b" }} />
                </ListItemIcon>
                {t('UNITS.show_activities')}
              </MenuItem> : null}
            {rolesRedux?.update_unit?.value ?
              <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <EditIcon style={{ fill: "#17681b" }} />
                </ListItemIcon>
                {t('UNITS.update_title')}
              </MenuItem> : null}
            {rolesRedux?.full_update_main_unit?.value ?
              <MenuItem onClick={() => { fullUpdateFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <EditIcon style={{ fill: "#17681b" }} />
                </ListItemIcon>
                {t('UNITS.full_update_title')}
              </MenuItem> : null}
            {rolesRedux?.update_unit?.value && rolesRedux?.refresh_unit_from_wialon?.value ?
              <MenuItem onClick={() => { refreshFromWialon({ main_unit_id: rows?.row?.id }, rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <RefreshIcon style={{ fill: "#17681b" }} />
                </ListItemIcon>
                {t('UNITS.refresh_data_from_wialon')}
              </MenuItem> : null}
            {rolesRedux?.update_unit?.value && rows?.row?.wialon_id ?
              <MenuItem onClick={() => { unlinkFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <LinkOffIcon style={{ fill: "#ff0000" }} />
                </ListItemIcon>
                {t('UNITS.unlink')}
              </MenuItem> : null}
            <MenuItem onClick={() => { copyFunction(rows?.row) }} className="ActionIcons">
              <ListItemIcon sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 7px 0 0'
              }}>
                <ContentCopyIcon style={{ fill: "#ff0000" }} />
              </ListItemIcon>
              {t('UNITS.copy_full_name')}
            </MenuItem>

          </DropDownGrid> : null
      ),
    },
  ]

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  const viewFunction = (obj) => {
    setselectedObject(obj)
    setOpenViewForm(true)
  }

  const getResources = () => {



    plateLetterAPI.getPlateLetterList({
      params: {
        ...headerObject,
      }

    }).then(plateLettersResult => {
      if (plateLettersResult.status && plateLettersResult?.data?.data) {
        setPlateLetters(plateLettersResult?.data?.data)
      }
      setLoadingPlateLetters(false);
    }).catch(error => {
      console.log(error);
      setLoadingPlateLetters(false);
    }).finally(() => {
      setLoadingPlateLetters(false);
    })

    plateTypeAPI.getPlateTypeList({
      params: {
        ...headerObject,
      }

    }).then(plateTypesResult => {
      if (plateTypesResult.status && plateTypesResult?.data?.data) {
        setPlateTypes(plateTypesResult?.data?.data)
      }
      setLoadingPlateTypes(false);
    }).catch(error => {
      console.log(error);
      setLoadingPlateTypes(false);
    }).finally(() => {
      setLoadingPlateTypes(false);
    })

    plateProvinceAPI.getPlateProvinceList({
      params: {
        ...headerObject,
      }

    }).then(plateProvincesResult => {
      if (plateProvincesResult.status && plateProvincesResult?.data?.data) {
        setPlateProvinces(plateProvincesResult?.data?.data)
      }
      setLoadingPlateProvinces(false);
    }).catch(error => {
      console.log(error);
      setLoadingPlateProvinces(false);
    }).finally(() => {
      setLoadingPlateProvinces(false);
    })

    userAPI.getUserList({
      params: {
        ...headerObject,
      }

    }).then(usersResult => {
      if (usersResult.status && usersResult?.data?.data) {
        setUsers(usersResult?.data?.data?.map(user => {

          let formattedName = 'لايوجد'
          if (user?.name && user?.wialon_user) {
            formattedName = user?.name + "(" + user?.wialon_user + ")";
          }
          else if (user?.name) {
            formattedName = user?.name
          }

          return {
            ...user,
            name: formattedName
          }
        }))
      }
      setLoadingUsers(false);
    }).catch(error => {
      console.log(error);
      setLoadingUsers(false);
    }).finally(() => {
      setLoadingUsers(false);
    })


  }

  useEffect(() => {
    getResources()
    return () => {

    }
  }, [])

  useEffect(() => {
    loadData()
  }, [pageNumber, pageSize, reload]);

  const loadAndRefreshData = async (unit) => {
    refreshFromWialon({ main_unit_id: unit?.id }, unit)
  }

  const loadData = async (searchParams) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        ...headerObject,
        ...((filters?.export_excel || filters.export_special_excel) ? {} :
          {
            page: pageNumber,
            page_size: pageSize,
          }),
        search: "",
        ...searchParams,
        ...filters
      };

      setLoading(true);

      const result = await mainUnitApi.mainUnitsTemp({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      setSelectionModel([])
      setSelectionRows([])

      if (result.status && result?.data?.data) {


        if (filters?.export_excel || filters.export_special_excel) {
          setFilters({
            ...filters,
            export_excel: false,
            export_special_excel: false
          })
          downloadExcel(result?.data?.data)
        }
        else {
          if (result?.data?.data?.data?.length > 0) {
            setRows(result?.data?.data?.data);
            setRowsTotal(result?.data?.data?.total_records);
            setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
          }
          else {
            setRows([]);
            setRowsTotal(0);
            setPageCount(0);
          }
        }
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };

  const loadAllDataIds = async () => {

    let object = {
      ...headerObject,
      ...filters,
      only_ids: 1
    };

    setLoading(true);

    const result = await mainUnitApi.mainUnitsTemp({
      params: object,
      signal: controller?.signal,
    });
    setLoading(false);
    return result



  };

  const refreshFromWialon = async (params, unit) => {

    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      // swal({
      //   title: t('UNITS.refresh_type'),
      //   text: t('UNITS.select_refresh_type'),
      //   icon: 'info',
      //   buttons: [t('UNITS.refresh_from_wialon'), t('UNITS.refresh_from_db')],
      //   dangerMode: true,
      // }).then(async (yes) => {

      //   let refreshType = 1
      //   if (yes) {
      //     refreshType = 1

      //   } else {
      //     refreshType = 0
      //   }

      let object = {
        username: cookies.get("user_name") ? cookies.get("user_name") : null,
        user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
        account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
        account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
        authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
        sid: cookies.get("sid") ? cookies.get("sid") : null,
        baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
        id: cookies.get("id") ? cookies.get("id") : null,
        ...params,
        // refreshType: refreshType

      };

      setLoading(true);

      const result = await mainUnitApi.refreshDataFromWialon({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data) {

        if (result?.data?.status && Array.isArray(result?.data?.data?.items)) {
          try {
            setselectedObject({ ...unit, id: params?.main_unit_id })
            setOpenWialonLinkForm(true)
            let wialonUnitsItems = result?.data?.data?.items?.map(item => {
              let accountName = "";

              const account = result?.data?.data?.accounts?.result?.find(subItem => subItem?.account_id == item?.bact)
              if (account) {
                accountName = " " + account?.account_name
              }
              return {
                ...item,
                name: item?.nm + accountName,
                wialon_name: item?.nm,
                main_account_id: item?.bact,
                wialon_id: item?.id,
                installation_date: item?.ct,
                account_name: accountName
              }
            })

            setWialonUnits(wialonUnitsItems)
          } catch (error) {
            console.log("error", error);
          }


        }
        else {
          loadData()
        }

      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
      // })
    } else {
      navigate("/redirect");
    }
  };


  const createFunction = (type) => {
    if (type == "refresh") {
      refreshFromWialon()
    }
    else if (type == "refresh_not_updated_only") {
      refreshFromWialon({ has_wialon_id: 0 })
    }
    else if (type == "clearAll") {
      swal({
        title: t('GLOBAL.are_you_sure'),
        text: t('UNITS.clear_all'),
        icon: 'warning',
        buttons: [t('GLOBAL.no'), t('GLOBAL.yes')],
        dangerMode: true,
      }).then(async (clearAllResult) => {

        if (clearAllResult) {
          clearAll()
        } else {
          // alert('canceled')
        }
      })
    }
    else {
      setselectedObject(null);
      setOpenForm(true);
    }
  };
  const showLogsFunction = (obj) => {
    setselectedObject(obj)
    setShowLogs(true)
  }
  const showActivitiesFunction = (obj) => {
    setselectedObject(obj)
    setShowActivities(true)
  }
  const updateFunction = (obj) => {
    setselectedObject(obj)
    setOpenForm(true)
  }
  const fullUpdateFunction = (obj) => {
    setselectedObject(obj)
    setOpenFullUpdateForm(true)
  }

  const clearAll = async () => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        username: cookies.get("user_name") ? cookies.get("user_name") : null,
        user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
        account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
        account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
        authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
        sid: cookies.get("sid") ? cookies.get("sid") : null,
        baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
        id: cookies.get("id") ? cookies.get("id") : null,

      };

      setLoading(true);

      const result = await mainUnitApi.clearAll({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data) {
        loadData()
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };

  const clearFilterFunction = () => {
    setFilters({
      creation_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
      creation_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
      creationDateFrom: yesterday,
      creationDateTo: todayEnd,
      plate_number: "",
      receipt_number: "",
      company_name: "",
      is_subscribed: -1,
      is_installed_custom: -1,
      is_installed: -1,
      update_note: "",
      export_excel: false,
      export_special_excel: false,
      receipt_date_from: moment().subtract(1, "day").startOf("day").format('YYYY-MM-DD') + " 00:00:00",
      receipt_date_to: moment().endOf('day').format('YYYY-MM-DD') + " 23:59:59",
      receiptDateFrom: yesterday,
      receiptDateTo: todayEnd,
      all_time_receipt_date: 1,
      all_time: 1,
      vin_number: "",
      tid: "",
      epc: "",
      active: -1
    })
  }
  const searchFunction = () => {
    setReload(!reload)
    setPageNumber(1)
    setSelectionRows([])
    setSelectionModel([])
  };
  const importFunction = () => {
    setOpenImportForm(true)
  }
  const exportFunction = () => {

    setFilters({
      ...filters,
      export_excel: true
    })
    setReload(!reload)
  }
  const exportSpecialFunction = () => {
    setFilters({
      ...filters,
      export_special_excel: true
    })
    setReload(!reload)
  }
  const downloadExcel = async (fileName) => {
    const result = await mainUnitApi?.downloadExcel({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }

  const getRowClassName = (params) => {
    if (selectionModel.includes(params?.row?.id)) {
      // If the row is selected, apply a custom class for styling
      return 'highlighted-checked-row';
    }
    if (params?.row?.active == 0) {
      return 'highlighted-not-active-row'
    }

    if (params?.row?.unlink == 1) {
      return 'highlighted-unlinked-row'
    }

    if (params?.row?.has_wialon_duplicate == 1) {
      return 'highlighted-has-wialon-duplicate-row'
    }

    if (params?.row?.enabled === 0) {
      return 'highlighted-disabled-row'
    }
    else {
      if (params?.row?.is_installed === 0) {
        if (params?.row?.has_duplicate == 1) {
          return 'highlighted-duplicate-row'
        }
        return 'highlighted-invalid-row'
      }
      else if (params?.row?.is_installed === 1) {
        if (params?.row?.has_duplicate == 1) {
          return 'highlighted-valid-duplicate-row'
        }
        return 'highlighted-valid-row'
      }
      else {
        return ''
      }
    }




  };

  const bulkUpdate = async (props) => {


    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      try {
        let readyToSendObject = {}
        readyToSendObject["main_units_ids"] = selectionModel;
        readyToSendObject["is_subscribed"] = props?.is_subscribed
        readyToSendObject["wialon_id"] = props?.wialon_id
        readyToSendObject["main_account_id"] = props?.main_account_id
        readyToSendObject["installation_date"] = props?.installation_date
        readyToSendObject["wialon_name"] = props?.wialon_name
        readyToSendObject["is_installed"] = props?.is_installed
        readyToSendObject["refresh_data_from_wialon"] = props?.refresh_data_from_wialon
        readyToSendObject["delete"] = props?.delete
        readyToSendObject["epc"] = props?.epc
        readyToSendObject["tid"] = props?.tid
        readyToSendObject["tag_id"] = props?.tag_id
        readyToSendObject["unlink"] = props?.unlink
        let params = {
          username: cookies.get("user_name"),
          user_id: cookies.get("user_id"),
          account_name: cookies.get("account_name"),
          account_id: cookies.get("account_id"),
          sid: cookies.get("sid"),
          baseurl: cookies.get("baseUrl"),
          id: cookies.get("id"),
        };

        let result = null;
        setLoading(true);

        result = await mainUnitApi.bulkUpdate({
          data: readyToSendObject,
          params: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });


        if (result?.status && result?.data?.status) {

          setLoading(false);

          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));

          loadData()
        } else {

          setLoading(false);

          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }
        setLoading(false);
      } catch (error) {

        setLoading(false);
        toast.error(error.message);
      }
    } else {

      // navigate('/redirect')
    }
  }

  const copyFunction = (unitDB) => {

    let fullPlateNumber = ""
    if (helpers.isNumericIntegerBetween11And99(unitDB?.plate_province))//new
    {
      // "21A83391;حمل;جديد",
      fullPlateNumber = unitDB?.plate_province + unitDB?.plate_letter + unitDB?.plate_number + ";" + unitDB?.plate_type + ";جديد"
    }
    else if (unitDB?.plate_letter) {
      //"ب14679;حمل;الانبار",
      let plate_letter_formatted = unitDB?.plate_letter
      fullPlateNumber = plate_letter_formatted + unitDB?.plate_number + ";" + unitDB?.plate_type + ";" + unitDB?.plate_province


    }
    else {
      // "4438;حمل;صلاح الدين",
      fullPlateNumber = unitDB?.plate_number + ";" + unitDB?.plate_type + ";" + unitDB?.plate_province
    }
    // Define the value you want to copy
    const value = `${fullPlateNumber}`; // Adjust as needed

    // Use navigator.clipboard to copy the text
    navigator.clipboard.writeText(value)
      .then(() => {
        swal("تم نسخ الرقم", fullPlateNumber, "info")

      })
      .catch((err) => {
        swal("فشل نسخ الرقم", "فشل نسخ الرقم : " + err?.message, "error")

      });
  };

  const unlinkFunction = (obj) => {
    swal({
      title: t('GLOBAL.are_you_sure'),
      text: t('ROUTES.wialon_link_will_be_removed'),
      icon: 'warning',
      buttons: [t('GLOBAL.no'), t('GLOBAL.yes')],
      dangerMode: true,
    }).then(async (updateWithWialonLink) => {

      if (updateWithWialonLink) {
        unlinkObject(obj)
      } else {
        // alert('canceled')
      }
    })
  }
  const unlinkObject = async (object) => {
    let readyToSendObject = {}


    readyToSendObject["wialon_id"] = "";
    readyToSendObject["main_account_id"] = "";
    readyToSendObject["installation_date"] = "";
    readyToSendObject["wialon_name"] = "";
    readyToSendObject["is_installed"] = 0;
    readyToSendObject["epc"] = "";
    readyToSendObject["tid"] = "";
    readyToSendObject["tag_id"] = "";
    readyToSendObject["unlink"] = 1;
    readyToSendObject["has_wialon_duplicate"] = 0;


    let params = {
      ...headerObject
    };

    let result = null;
    if (object && object?.id) {
      readyToSendObject["id"] = object?.id;

      try {
        result = await mainUnitApi.editMainUnitTemp({
          data: readyToSendObject,
          params: params,
          id: object?.id,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (result?.status && result?.data?.status) {



          toast.success(t("NETWORK_MESSAGES.infoAddedSuccessfully"));

          loadData();

        } else {


          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }

      } catch (error) {


        toast.error(error.message);
      }
    }
  }
  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >
      {showLogs ? (
        <MainUnitLogs
          object={selectedObject}
          openForm={showLogs}
          setOpenForm={setShowLogs}
          loadData={loadData}
        />
      ) : null}
      {showActivities ? (
        <MainUnitActivities
          object={selectedObject}
          openForm={showActivities}
          setOpenForm={setShowActivities}
          loadData={loadData}
        />
      ) : null}
      {openImportForm ? (
        <ImportFormContainer
          object={selectedObject}
          openForm={openImportForm}
          setOpenForm={setOpenImportForm}
          loadData={loadData}
        />
      ) : null}
      {openViewForm && selectedObject ? (
        <ViewFormContainer
          object={selectedObject}
          openForm={openViewForm}
          setOpenForm={setOpenViewForm}
        />
      ) : null}
      {openForm ? (
        <FormContainer
          object={selectedObject}
          openForm={openForm}
          setOpenForm={setOpenForm}
          loadData={loadData}
          loadAndRefreshData={loadAndRefreshData}
          loadingPlateLetters={loadingPlateLetters}
          loadingUsers={loadingUsers}
          loadingPlateProvinces={loadingPlateProvinces}
          loadingPlateTypes={loadingPlateTypes}
          plateLetters={plateLetters}
          plateProvinces={plateProvinces}
          plateTypes={plateTypes}
          users={users}
        />
      ) : null}
      {openFullUpdateForm ? (
        <FullUpdateFormContainer
          object={selectedObject}
          openForm={openFullUpdateForm}
          setOpenForm={setOpenFullUpdateForm}
          loadData={loadData}
        />
      ) : null}
      {openWialonLinkForm ? (
        <WialonLinksFormContainer
          object={selectedObject}
          openForm={openWialonLinkForm}
          setOpenForm={setOpenWialonLinkForm}
          loadData={loadData}

          wialonUnits={wialonUnits}
        />
      ) : null}

      <Title
        createFunction={createFunction}
        selectionModel={selectionModel}
        bulkUpdate={bulkUpdate}
        openFilterColumn={openFilterColumn}
      />
      <Grid
        rows={rows}

        hasSelectOption={true}


        listOfSelectedRows={selectionModel?.filter(item =>
          rows?.map(
            (row) =>
              row?.id
          )?.includes(item)
        )
        }
        handleCheckBoxChange={(newSelectionModel) => {



          const rowsIds = rows?.map((row) => row?.id)
          const selectionModelWithOUtCurrentRowsIds = selectionModel?.filter((item) => {

            if (!rowsIds?.includes(item)) {
              return true
            }
            else {
              return false
            }
          })
          const selectionRowWithOUtCurrentRows = selectionRows?.filter(item => !rowsIds?.includes(item))
          const newSelectionRows = rows?.filter(item => newSelectionModel?.includes(item?.id))?.map(row => row?.id)
          const updatedSelectionModel = [
            ...selectionModelWithOUtCurrentRowsIds,
            ...newSelectionModel
          ]
          const updatedSelectionRows = [
            ...selectionRowWithOUtCurrentRows,
            ...newSelectionRows
          ]


          if (newSelectionModel?.length == pageSize) {
            swal({
              title: t('GLOBAL.are_you_sure_select_all'),
              text: t('GLOBAL.select_all_items_in_all_pages'),
              icon: 'info',
              buttons: [t('GLOBAL.no'), t('GLOBAL.yes')],
              dangerMode: true,
            }).then(async (yes) => {

              if (yes) {
                const result = await loadAllDataIds()
                if (result?.data?.data?.length > 100) {
                  toast.warning('لايمكن تحديث اكثر من 1000 عنصر قم باختيرا عناصر اقل')
                  return
                }
                if (result.status && result?.data?.data) {
                  setSelectionRows(result?.data?.data?.map(item => item?.id))
                  setSelectionModel(result?.data?.data?.map(item => item?.id))
                } else {
                  toast.warning('حصل خطا في تحميل البيانات')
                }
              } else {
                // alert('canceled')
                setSelectionRows(updatedSelectionRows)
                setSelectionModel(updatedSelectionModel)

              }
            })
          }
          else if (newSelectionModel?.length == 0 && updatedSelectionModel.length > 0) {
            swal({
              title: t('GLOBAL.are_you_sure_unselect_all'),
              text: t('GLOBAL.unselect_all_items_in_all_pages'),
              icon: 'info',
              buttons: [t('GLOBAL.no'), t('GLOBAL.yes')],
              dangerMode: true,
            }).then(async (yes) => {

              if (yes) {
                setSelectionRows([])
                setSelectionModel([])


              } else {
                // alert('canceled')
                setSelectionRows(updatedSelectionRows)
                setSelectionModel(updatedSelectionModel)

              }
            })
          }
          else {
            setSelectionRows(updatedSelectionRows)
            setSelectionModel(updatedSelectionModel)
          }


        }}

        hasExport={(cookies.get('id') != 46) && rolesRedux?.export_unit?.value}
        exportFunction={exportFunction}
        multipleExports={(cookies.get('id') == 46)  ?
                  [
                    { icon: <SiMicrosoftexcel color="green" size={"25"} />, name: t('TRANSACTIONS.exportExcel'), key: 'report1', onClick: () => { exportFunction() } },
                    { icon: <SiMicrosoftexcel color="red" size={"25"} />, name: t('TRANSACTIONS.exportExcelSpecial'), key: 'report4', onClick: () => { exportSpecialFunction() } },
                  ] : []
                }
        getRowClassName={getRowClassName}
        hasImport={rolesRedux?.add_geofence?.value}
        importFunction={importFunction}

        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}


        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}
            loadingPlateLetters={loadingPlateLetters}
            loadingUsers={loadingUsers}
            loadingPlateProvinces={loadingPlateProvinces}
            loadingPlateTypes={loadingPlateTypes}
            plateLetters={plateLetters}
            plateProvinces={plateProvinces}
            plateTypes={plateTypes}
            users={users}
          />
        }
      />

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          marginTop: '10px'
        }}
      >


        <Box sx={{
          display: 'flex',

          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '35px',
        }}>
          <Box sx={{
            display: 'block',
            width: '25px',
            height: '25px',
            marginRight: '5px',
            backgroundColor: '#74BDCB',
          }}>

          </Box>
          <Typography sx={{
            fontFamily: 'Cairo',
            marginRight: '10px'
          }}>
            {t('UNITS.unlink')}
          </Typography>

        </Box>

        <Box sx={{
          display: 'flex',

          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '35px',
        }}>
          <Box sx={{
            display: 'block',
            width: '25px',
            height: '25px',
            marginRight: '5px',
            backgroundColor: '#D8A7B1',
          }}>

          </Box>
          <Typography sx={{
            fontFamily: 'Cairo',
            marginRight: '10px'
          }}>
            {t('UNITS.has_wialon_duplicate')}
          </Typography>

        </Box>
        <Box sx={{
          display: 'flex',

          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '35px',
        }}>
          <Box sx={{
            display: 'block',
            width: '25px',
            height: '25px',
            marginRight: '5px',
            backgroundColor: '#b1b0b0',
          }}>

          </Box>
          <Typography sx={{
            fontFamily: 'Cairo',
            marginRight: '10px'
          }}>
            {t('UNITS.disabled')}
          </Typography>

        </Box>

        <Box sx={{
          display: 'flex',

          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '35px',
        }}>
          <Box sx={{
            display: 'block',
            width: '25px',
            height: '25px',
            marginRight: '5px',
            backgroundColor: '#d1e72a',
          }}>

          </Box>
          <Typography sx={{
            fontFamily: 'Cairo',
            marginRight: '10px'
          }}>
            {t('UNITS.has_duplicate')}
          </Typography>

        </Box>

        <Box sx={{
          display: 'flex',

          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '35px',
        }}>
          <Box sx={{
            display: 'block',
            width: '25px',
            height: '25px',
            marginRight: '5px',
            backgroundColor: '#f4bdbd',
          }}>

          </Box>
          <Typography sx={{
            fontFamily: 'Cairo',
            marginRight: '10px'
          }}>
            {t('UNITS.not_connected')}
          </Typography>

        </Box>
        <Box sx={{
          display: 'flex',
          width: '150px',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '35px',
        }}>
          <Box sx={{
            display: 'block',
            width: '25px',
            height: '25px',
            marginRight: '5px',
            backgroundColor: '#a1bae9',
          }}>

          </Box>
          <Typography sx={{
            fontFamily: 'Cairo',
            marginRight: '10px'
          }}>
            {t('UNITS.connected_duplicate')}
          </Typography>

        </Box>
        <Box sx={{
          display: 'flex',
          width: '150px',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '35px',
        }}>
          <Box sx={{
            display: 'block',
            width: '25px',
            height: '25px',
            marginRight: '5px',
            backgroundColor: '#d4edda',
          }}>

          </Box>
          <Typography sx={{
            fontFamily: 'Cairo',
            marginRight: '10px'
          }}>
            {t('UNITS.connected')}
          </Typography>

        </Box>
        <Box sx={{
          display: 'flex',
          width: '150px',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '35px',
        }}>
          <Box sx={{
            display: 'block',
            width: '25px',
            height: '25px',
            marginRight: '5px',
            backgroundColor: '#e7a52a',
          }}>

          </Box>
          <Typography sx={{
            fontFamily: 'Cairo',
            marginRight: '10px'
          }}>
            {t('UNITS.selected')}
          </Typography>

        </Box>
        <Box sx={{
          display: 'flex',
          width: '150px',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '35px',
        }}>
          <Box sx={{
            display: 'block',
            width: '25px',
            height: '25px',
            marginRight: '5px',
            backgroundColor: '#efe7bc',
          }}>

          </Box>
          <Typography sx={{
            fontFamily: 'Cairo',
            marginRight: '10px'
          }}>
            {t('UNITS.not_active')}
          </Typography>

        </Box>

      </Box>
    </Box>
  );
}
