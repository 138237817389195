import React, { useState } from "react";
import { Switch, Checkbox, Typography, Box, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomList = ({columns,setColumns}) => {
    const { t } = useTranslation('common');
//   const [columns, setColumns] = useState([
//     { field: 1, headerName: "Item 1", sort: false, asc: false },
//     { field: 2, headerName: "Item 2", sort: false, asc: false },
//     { field: 3, headerName: "Item 3", sort: false, asc: false },
//   ]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("dragIndex", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropIndex) => {
    const dragIndex = parseInt(e.dataTransfer.getData("dragIndex"));
    if (dragIndex === dropIndex) return;

    const updatedItems = [...columns];
    const [draggedItem] = updatedItems.splice(dragIndex, 1);
    updatedItems.splice(dropIndex, 0, draggedItem);
    setColumns(updatedItems);
  };

  const toggleChecked = (field) => {
    setColumns((prevItems) =>
      prevItems.map((item) =>
        item.field === field ? { ...item, sort: !item.sort } : item
      )
    );
  };

  const toggleSwitch = (field) => {
    setColumns((prevItems) =>
      prevItems.map((item) =>
        item.field === field ? { ...item, asc: !item.asc } : item
      )
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "auto",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
         
      
      {columns.map((item, index) => (
        <Box
          key={item?.field}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, index)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px",
            marginBottom: "10px",
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderRadius: "5px",
            cursor: "grab",
          }}
        >
          <Checkbox
            checked={item?.sort}
            sx={{flex:0.2}}
            onChange={() => toggleChecked(item?.field)}
          />
          <Typography sx={{fontFamily: 'Cairo-Medium',flex:1}}>{item?.headerName}</Typography>
          
          <FormControlLabel control={<Switch checked={item?.asc} onChange={() => toggleSwitch(item?.field)} />} 
          sx={{ fontFamily: 'Cairo-Medium',width:'100px' }} label={item?.asc ? t('GLOBAL.asc') : t('GLOBAL.desc')} />
        </Box>
      ))}
    </Box>
  );
};

export default CustomList;
