import { Box, Button, Divider, Tooltip, Typography } from '@mui/material'
import React from 'react'
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectRoles } from '../../reduxStore/RolesReducer';
import MoreMenu from '../../Components/MoreMenu';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Cookies from "universal-cookie";
const cookies = new Cookies();

function Title({ createFunction, openFilterColumn, bulkUpdate, selectionModel }) {
    const [t] = useTranslation("common");
    const rolesRedux = useSelector(selectRoles)
    const gridtheme = useSelector((state) => state.themeData.gridtheme);
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "stretch",
                    position: "relative",
                }}
            >

                <Box
                    sx={{
                        flex: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            marginLeft: "10px",
                            marginBottom: "10px",
                            fontFamily: "Cairo-Bold",
                            fontSize: "23px",
                            letterSpacing: "-1px",
                        }}
                    >
                        {t("UNITS.receipts")}
                    </Typography>
                </Box>


            </Box>
            <Divider
                sx={{
                    backgroundColor: "#EBF2F7",
                    margin: "30px",
                    marginTop: "10px",
                    width: "100%",
                }}
                style={{
                    height: "3px",
                }}
            />
        </>
    )
}

export default Title