import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import swal from "sweetalert";
import ViewFormContainer from './ViewForm/ViewFormContainer';

const InstallationCheckCard = ({ mainUnit, onClick }) => {
    const [openViewForm, setOpenViewForm] = useState(false)
    const [unitState, setUnitState] = useState(null)
    const getUnitPlate = (withUnitState) => {

        let unitState = ""
        if (withUnitState) {
            if (mainUnit?.active == 0) {
                unitState = "المركبة غير فعالة"
            }
            else {
                if (mainUnit?.has_device_and_online == -1) {
                    unitState = "الجهاز غير مركب"
                }
                else if (mainUnit?.has_device_and_online == 0) {
                    unitState = "الجهاز مركب ولكن غير متصل"
                }
                else if (mainUnit?.has_device_and_online == 1) {
                    unitState = "الجهاز مركب ومتصل"
                }
            }
            
        }

        let unitPlate = (mainUnit?.plate_number ? mainUnit?.plate_number?.toString() + " " : "")
            + (mainUnit?.name_and_type ? mainUnit?.name_and_type?.toString() + " " : "")
            + (mainUnit?.plate_province ? mainUnit?.plate_province?.toString() : "")

        return unitState + " \n " + unitPlate
    }
    const getUnitType = () => {
        let unitName = "";

        if (mainUnit?.plate_type) {
            unitName += mainUnit?.plate_type + " "
        }

        if (mainUnit?.name_and_type) {
            unitName += mainUnit?.name_and_type + " "
        }
        return unitName
    }
    useEffect(() => {

        return () => {

        }
    }, [openViewForm])

    const getFontColor = () => {
        if (mainUnit?.active == 1) {
            if (mainUnit?.is_subscribed == 1 && mainUnit?.has_device_and_online == 1) {
                return '#000000'
            }
            else {
                return '#FFFFFF'
            }
        }
        else {
            return '#000000'
        }
    }
    const getBackgroundColor = () => {
        if (mainUnit?.active == 1) {
            if (mainUnit?.is_subscribed == 1 && mainUnit?.has_device_and_online == 1) {
                return '#d4edda'
            }
            else {
                if (mainUnit?.is_subscribed == 1 && mainUnit?.has_device_and_online == 0) {
                    return '#e7a52a'
                }
                else {
                    return '#8f1c24'
                }
            }
        }
        else {
            return '#efe7bc'
        }
    }


    return (
        <Button onClick={() => {
            if (mainUnit?.active == 1 && mainUnit?.is_subscribed == 1 && mainUnit?.has_device_and_online == 1) {


                setUnitState({
                    title: "المركبة مخولة",
                    text: getUnitPlate(true),
                    icon: "check"
                })




            }
            else {

                setUnitState({
                    title: "المركبة غير مخولة",
                    text: getUnitPlate(true),
                    icon: "error"
                })
            }
            if (!openViewForm) {
                setOpenViewForm(true)
            }

        }}>


            <Box

                sx={{
                    width: '500px',
                    height: '180px',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    backgroundColor: getBackgroundColor(),

                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    ":hover": {
                        cursor: 'pointer',
                        transform: 'translateY(-10px)',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
                        backgroundColor: getBackgroundColor()+"A0 !important",
                    }
                }}

            >
                {openViewForm ? (
                    <ViewFormContainer
                        object={mainUnit}
                        unitState={unitState}
                        openForm={openViewForm}
                        setOpenForm={(value) => {


                            setOpenViewForm(false)
                        }}
                    />
                ) : null}
                <Box
                    sx={{
                        cursor: 'pointer',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '10px'
                    }}
                >



                    <Typography
                        sx={{

                            fontFamily: 'Cairo',

                            color: getFontColor(),
                            fontSize: '14px',
                            marginTop: '10px',
                            textAlign: 'center',
                            direction: 'rtl'
                        }}
                    >
                        {getUnitPlate()}
                    </Typography>
                    <Typography
                        sx={{

                            fontFamily: 'Cairo',

                            color: getFontColor(),
                            fontSize: '14px',
                            marginTop: '10px',
                            textAlign: 'center'
                        }}
                    >
                        {getUnitType()}
                    </Typography>
                    <Typography
                        sx={{

                            fontFamily: 'Cairo',

                            color: getFontColor(),
                            fontSize: '14px',
                            marginTop: '10px',
                            textAlign: 'center'
                        }}
                    >
                        {mainUnit?.driver_name ? mainUnit?.driver_name : ""}
                    </Typography>
                    <Typography
                        sx={{

                            fontFamily: 'Cairo',

                            color: getFontColor(),
                            fontSize: '14px',
                            marginTop: '10px',
                            textAlign: 'center'
                        }}
                    >
                        {mainUnit?.company_name ? mainUnit?.company_name : ""}
                    </Typography>
                    <Typography
                        sx={{

                            fontFamily: 'Cairo',

                            color: getFontColor(),
                            fontSize: '14px',
                            marginTop: '10px',
                            textAlign: 'center'
                        }}
                    >
                        {mainUnit?.note ? mainUnit?.note : ""}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        cursor: 'pointer',
                        width: '50px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {mainUnit?.active == 1 && mainUnit?.is_subscribed == 1 && mainUnit?.has_device_and_online == 1 ?
                        <CheckCircleIcon
                            style={{
                                color: '#266713',
                                fontSize: '25px'
                            }}
                        />
                        :
                        <CancelIcon
                            style={{
                                color: '#FFFFFF',
                                fontSize: '25px'
                            }}
                        />}
                </Box>
            </Box>
        </Button>

    )
}

export default InstallationCheckCard