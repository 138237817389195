import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomeSelectField from "../../Components/CustomeSelectField";
import PopupForm from "../../Components/PopupForm";

import ObjectOfErrors, {

} from "../../Errors/MainUnits/MainUnit";

import helpers from "../../assets/js/helper";
import plateProvinceAPI from "../../Network/PlateProvince";


import mainUnitApi from "../../Network/MainUnit";
import CustomDateField from "../../Components/CustomDateField";
const cookies = new Cookies();




const AddInstallationDate = (props) => {
  const jwt = cookies.get("token");

  var headers = {
    jwt: jwt,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorObject] = useState(ObjectOfErrors);
  const [reservedDate, setReservedDate] = useState(null);
  const [selectedPlateProvince, setSelectedPlateProvince] = useState(null);
  const [plateProvinces, setPlateProvinces] = useState([]);
  const [countData, setCountData] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [keyCount, setKeyCount] = useState(1);
  const [isMulti, setIsMulti] = useState(false)
  useEffect(() => {
    if (props?.object) {
      if (!isMulti) {
        setSelectedPlateProvince(props.object?.plate_province);
      }
      if (props?.object?.id) {
        getMainUnitsWithReservations();
      }
    }
  }, [props?.object]);

  const submit = async () => {
    if (!reservedDate) {
      toast.warn("يجب تحديد تاريخ الحجز");
      return;
    }
    if (!selectedPlateProvince) {
      toast.warn("يجب تحديد المحافظة");
      return;
    }

    try {
      let dataList = [];
      vehicleList?.map((item) => {
        if (item?.checkReserved) {
          dataList?.push({
            reservation_date: reservedDate
              ? helpers.formatDate(reservedDate) +
              " " +
              helpers?.formatTime(reservedDate)
              : null,
            plate_province_id: selectedPlateProvince?.id,
            main_unit_id: item?.id,
          });
        }
      });
      if (dataList?.length <= 0) {
        toast.warn("يجب اختيار مركبة واحدة على الاقل!");
        return;
      }
      const data = await mainUnitApi.addBulkReservation({
        data: dataList,
      });
      if (data && data?.status) {
        toast.success("تمت عملية الحجز بنجاح");
        props?.setAddForm(false);
      } else {
        if (data?.errorMessage && typeof data?.errorMessage == "string") {
          toast.warn(data?.errorMessage);
        } else {
          toast.warn("خطء غير معروف");
        }
      }
    } catch (err) {
      toast.warn(err?.message);
    }
  };
  const getMainUnitsWithReservations = async () => {
    if (!isMulti && (!props?.object || !props?.object?.id)) {
      toast.warn("يجب تحديد معرف الطلب");
      return;
    } else if (
      isMulti &&
      (!props?.selectedIds || props?.selectedIds?.length <= 0)
    ) {
      toast.warn("يجب تحديد الطلبات");
      return;
    }

    try {
      const data = await mainUnitApi.mainUnitsWithReservations({
        params: {
          receipt_number: props?.selectedIds[0]
        },
        headers:headers
      });
      if (data && data?.status) {
        let list = data?.data;
        
       
        setVehicleList(list);
      } else {
      }
    } catch (err) { }
  };
  const getCountOfInstallationDate = async () => {
    if (!reservedDate) return;
    try {
      const data = await mainUnitApi.getCount({
        params: {
          reservation_date: helpers.formatDate(reservedDate),
        },
        headers:headers
      });
      if (data && data?.status) {
        setCountData(data?.data);
      } else {
      }
    } catch (err) { }
  };
  const loadMasterData = async () => {
    let data = null;
    try {
      setLoading(true);


      plateProvinceAPI.getPlateProvinceList({
        headers: headers

      }).then(plateProvincesResult => {
        if (plateProvincesResult?.status && plateProvincesResult?.data?.data) {
          setPlateProvinces(plateProvincesResult?.data?.data)
        }

      }).catch(error => {
        console.log(error);

      }).finally(() => {

      })
    } catch (err) {
      console.log(err?.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (isMulti && props?.selectedIds?.length) {
      getMainUnitsWithReservations();
    }
  }, [isMulti, props?.selectedIds]);
  useEffect(() => {
    if (reservedDate) {
      getCountOfInstallationDate();
    }
  }, [reservedDate]);
  useEffect(() => {
    if (props?.addForm) {
      loadMasterData();
    }
  }, [props.addForm]);

  const handleCheck = (obj, value) => {
    var arr = [];
    console.log("sdfsdfdsfds===>enter");
    vehicleList?.map((item) => {
      if (item?.id == obj?.id) {
        console.log(
          "sdfsdfdsfds===>check:",
          value,
          ", item:",
          item?.id,
          ", obj:",
          obj?.id
        );
        arr?.push({
          ...item,
          checkReserved: value,
        });
      } else arr?.push(item);
    });


    setVehicleList(arr);
    setKeyCount((item) => item + 1);
  };
  const sortByName = (data) => {
    const sortedData = [...data].sort((a, b) => {
      if (a.order_id < b.order_id) return -1;
      if (a.order_id > b.order_id) return 1;
      return 0;
    });
    return sortedData;
  };
  const handleCheckAll = (value) => {
    setSelectAll(value);
    var arr = [];
    vehicleList?.map((item) => {
      arr?.push({
        ...item,
        checkReserved: value && item?.vehicle_wialon_info?.length <= 0 ? true : false,
      });
    });

    setVehicleList(arr);
    setKeyCount((item) => item + 1);
  };
  return (
    <PopupForm
      open={props.addForm}
      setOpen={props.setAddForm}
      title={isMulti ? 'اضافة حجوزات للمركبات' : "اضافة حجز للمركبات التابعة للوصل " + props?.object?.receipt_number}
      width={"100%"}
      height={"100%"}
      customePadding={"0px"}
      customeHeight="100%"
      isFullScreen={true}
      content={
        <Box
          sx={{
            width: "100%",
          }}
          key={keyCount}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <span>
              عدد المركبات المحجوزة للتاريخ المحدد :{" "}
              {countData?.length && reservedDate ? countData?.length : 0}
            </span>
          </Box>
          <RenderSingleContent
            selectedPlateProvince={selectedPlateProvince}
            setSelectedPlateProvince={setSelectedPlateProvince}
            plateProvinces={plateProvinces}
            reservedDate={reservedDate}
            setReservedDate={setReservedDate}
            countData={countData}
            vehicleList={vehicleList}
            setVehicleList={setVehicleList}
            handleCheck={handleCheck}
            selectedVehicle={selectedVehicle}
            setSelectedVehicle={setSelectedVehicle}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            handleCheckAll={handleCheckAll}
          />
        </Box>
      }
      footer={
        <Button
          variant="outlined"
          // spacing={2}
          sx={{
            width: "50% !important",
            // flexGrow: 1,
            minWidth: "80px !important",
            maxWidth: "80px !important",
            margin: 1,
            backgroundColor: "#d1d0d0",
            borderColor: "#ffffff",
            color: "#000000",
            boxShadow: "0 0 7px -2px white",
            //   color:SearchButtonTheme?.clear_button_text_color,

            "&:hover": {
              backgroundColor: "#e7a52a",
              borderColor: "#ffffff",
              color: "#fff",
              boxShadow: "0 0 7px 1px white",
              boxShadow: "0",
            },
            height: "35px",
            fontFamily: "Cairo-Bold",
          }}
          className="iconeFilterClear"
          color="secondary"
          onClick={() => {
            submit()
            props?.setOpenForm(false);
          }}
        >
          {t("GLOBAL.close")}
        </Button>

      }
    />
  );
};
const RenderSingleContent = (props) => {
  const [t] = useTranslation("common");

  const getReservedDate = (item) => {
    let dateTime = "---";
    if (item?.reservation_date) {
     
      dateTime = item?.reservation_date
        ? helpers.formatDate(item?.reservation_date) +
        " " +
        helpers.formatTime(item?.reservation_date)
        : "---";
    }
    return dateTime;
  };
  const getReservedPlateProvince = (item) => {
    let goverName = "---";
    if (item?.reservation_id) {
      
      goverName = item?.plate_province_name;
    }
    return goverName ? goverName : "---";
  };
  const getBackgroundColor = (item, number) => {
    let colorV = '#f3f3f3';
    if (number == 2)
      colorV = "#17681b" + " !important"

    if (item?.vehicle_wialon_info?.length > 0) {
      if (number == 1)
        colorV = "#17681b";
      else
        colorV = '#ffffff';
    } else if (item?.last_vehicle_installation_dates?.id) {
      if (number == 1)
        colorV = '#0000008a';
      else
        colorV = '#ffffff';
    }
    return colorV;
  }
  const checkDisabled = (item) => {
    let check = false;
    if (item?.vehicle_wialon_info?.length > 0) {
      check = true
    }
    return check;
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 5,
      }}
    >
      {/* <Box
        sx={{
          marginTop: "10px",
          width: "30%",
        }}
      >
        <CustomeSelectField
          label={"المركبات"}
          haswidth={true}
          value={props?.selectedVehicle}
          list={props?.vehicleList ? props?.vehicleList : []}
          customGetOptionLabel={(option) => option?.customName || ""}
          error={false}
          message={[]}
          CustomFontSize="12px"
          multiple={false}
          disabled={false}
          readOnly={false}
          placeHolder="لايوجد"
          focused={true}
          required={true}
          customPadding="0px"
          paddingHorizontal={"0px"}
          showTheColor={true}
          onChange={(e, newValue) => {
            props?.setSelectedVehicle(newValue);
          }}
          onClearClick={() => {
            props?.setSelectedVehicle(null);
          }}
        />
      </Box> */}
      <Box
        sx={{
          marginTop: "10px",
          width: "30%",
        }}
      >
        <CustomDateField
          label={t('UNITS.reservation_date')}
          value={props?.reservedDate ? props?.reservedDate : null}
          error={false}
          message={""}
          haswidth={true}
          readOnly={false}
          minDate={null}
          maxDate={null}
          onChange={(date) => {
            props?.setReservedDate(date);
            // helpers.formatDateTime(date),


          }}
          onClearClick={() => {
            props?.setReservedDate(null);

          }}
        />

      </Box>
      <Box
        sx={{
          marginTop: "10px",
          width: "30%",
        }}
      >
        <CustomeSelectField
          label={"المحافظة"}
          haswidth={true}
          value={props?.selectedPlateProvince}
          list={props?.plateProvinces ? props?.plateProvinces : []}
          customGetOptionLabel={(option) => option?.name || ""}
          error={false}
          message={[]}
          CustomFontSize="12px"
          multiple={false}
          disabled={false}
          readOnly={false}
          placeHolder="لايوجد"
          focused={true}
          required={true}
          customPadding="0px"
          paddingHorizontal={"0px"}
          showTheColor={true}
          onChange={(e, newValue) => {
            props?.setSelectedPlateProvince(newValue);
          }}
          onClearClick={() => {
            props?.setSelectedPlateProvince(null);
          }}
        />
      </Box>
      {/* <Box
        sx={{
          marginTop: "10px",
          width: "15%",
        }}
      >
       <IconButton>
          <span>اضافة</span>
       </IconButton>
      </Box> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: "flex",
            alignItems: 'center',
            gap: 5
          }}
        >
          <Box
            sx={{
              width: "fit-content",
              height: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              boxShadow: "0 0 7px -2px #000000a0",
              padding: "10px 10px",
              gap: "15px",
            }}
          >
            <span>تشير الالوان الى : </span>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span
                style={{
                  backgroundColor: "#f3f3f3",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  boxShadow: "0 0 7px -2px #000000a0",
                }}
              ></span>
              <span>غير محجوز</span>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span
                style={{
                  backgroundColor: "#0000008a",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  boxShadow: "0 0 7px -2px #000000a0",
                }}
              ></span>
              <span>محجوز</span>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span
                style={{
                  backgroundColor: "#17681b",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  boxShadow: "0 0 7px -2px #000000a0",
                }}
              ></span>
              <span>تم التركيب ولايمكن الججز</span>
            </Box>
          </Box>
          <Box
            sx={{
              width: "fit-content",
              backgroundColor: '#ffffff',
              boxShadow: '0 0 7px -2px #000000a0',
              padding: '1px 10px'
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={props?.selectAll}
                  sx={{
                    fontFamily: 'Cairo !important'
                  }}
                  onChange={(e) => props?.handleCheckAll(e?.target?.checked)}
                />
              }

              label="تحديد الكل"
            />
          </Box>
        </Box>
        {props?.vehicleList?.length ? (
          props?.vehicleList?.map((item) => (
            <Box
              disabled={checkDisabled(item)}
              sx={{
                // display: "flex",
                // alignItems: "center",
                width: "fit-content",
                // flexWrap: "wrap",
                backgroundColor: getBackgroundColor(item, 1),
                color: getBackgroundColor(item, 2),
                padding: "5px 10px",
                // cursor: "pointer",
                gap: 1,
                "& .MuiCheckbox-root": {
                  color: getBackgroundColor(item, 2),
                },
                minHeight: "130px",
              }}
            // onClick={(e) => {
            //   props?.handleCheck(item,(item?.checkReserved?false:true));
            // }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  fontSize: "13px",
                }}
              >
                <Checkbox
                  checked={item?.checkReserved}
                  onChange={(e) => {
                    props?.handleCheck(item, e?.target?.checked);
                  }}
                  disabled={checkDisabled(item)}
                />

                <Box
                  sx={{
                    width: "100%",
                    paddingLeft: "10px",
                    // display: "flex",
                    // alignItems: "center",
                    // gap: "10px",
                    // textAlign: 'left',
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <span>رقم الطلب : </span>
                    <span
                      style={{
                        direction: "ltr",
                      }}
                    >
                      {item?.receipt_number
                        ? item?.receipt_number
                        : "---"}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <span>رقم ونوع المركبة : </span>
                    <span style={{}}>
                      {item?.plate_number
                        ? item?.plate_number
                        : "---"}
                      ,
                      {item?.name_and_type
                        ? item?.name_and_type
                        : "---"}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <span> تفاصيل المركبة : </span>
                    <span style={{}}>
                      {item?.plate_type
                        ? item?.plate_type + ", "
                        : "---, "}

                      {item?.plate_province
                        ? item?.plate_province + ", "
                        : "---, "}

                      {item?.plate_letter
                        ? item?.plate_letter + ", "
                        : "---, "}
                    </span>
                  </Box>
                  {item?.reservation_id ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <span>تاريخ الحجز : </span>
                        <span
                          style={{
                            direction: "ltr",
                          }}
                        >
                          {getReservedDate(item)}
                        </span>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <span> محافظة الحجز : </span>
                        <span
                          style={{
                            direction: "ltr",
                          }}
                        >
                          {getReservedPlateProvince(item)}
                        </span>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <span> عدد التحديثات للحجز : </span>
                        <span
                          style={{
                            direction: "ltr",
                          }}
                        >
                          {item?.change_count}
                        </span>
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Box>
            <span>لاتوجد مركبات</span>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddInstallationDate;
