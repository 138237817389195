import React, { useState, useEffect } from "react";
import Grid from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography,
  Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "universal-cookie";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import StarsIcon from "@mui/icons-material/Stars";
import { useNavigate } from "react-router-dom";
import Search from "./Search";
import geofenceAPI from "../../Network/Geofence";
import FormContainer from "./Form/FormContainer";
import Title from "./Title";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import branchAPI from '../../Network/Branch';
import portTypeAPI from '../../Network/PortType';
import portCategoryAPI from '../../Network/PortCategory';
import portReturnAPI from '../../Network/PortReturn';
import { selectRoles } from "../../reduxStore/RolesReducer";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';
import swal from "sweetalert";
import UpdateNameFormContainer from "./UpdateNameForm/UpdateNameFormContainer";
import ImportFormContainer from "./ImportForm/ImportFormContainer";
import UpdateCodeFormContainer from "./UpdateCodeForm/UpdateCodeFormContainer";

const cookies = new Cookies();

export default function Geofences() {
  const [branches, setBranches] = useState([])
  const [loadingBranches, setLoadingBranches] = useState(true)

  const [portTypes, setPortTypes] = useState([])
  const [loadingPortTypes, setLoadingPortTypes] = useState(true)

  const [portCategories, setPortCategories] = useState([])
  const [loadingPortCategories, setLoadingPortCategories] = useState(true)

  const [portReturns, setPortReturns] = useState([])
  const [loadingPortReturns, setLoadingPortReturns] = useState(true)


  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);

  const [filters, setFilters] = useState({
    name: "",
    code: "",
    port_type_id: null,
    branch_id: null,
    port_category_id: null,
    port_return_id: null,
    port_code: "",
    wialon_id: null,
    export_excel: false
  })
  const [reload, setReload] = useState(false)
  const [openImportForm, setOpenImportForm] = useState(false)

  const [openForm, setOpenForm] = useState(false);
  const [openUpdateNameForm, setOpenUpdateNameForm] = useState(false)
  const [openUpdateCodeForm, setOpenUpdateCodeForm] = useState(false)
  const [selectedObject, setselectedObject] = useState(null);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const rolesRedux = useSelector(selectRoles)

  const [sortColumns, setSortColumns] = useState([
    { field: "name", headerName: t("GEOFENCES.name") },
    { field: "code", headerName: t("GEOFENCES.code") },
    { field: "port_code", headerName: t("GEOFENCES.port_code") },
    { field: "port_type_id", headerName: t("GEOFENCES.port_type") },
    { field: "port_return_id", headerName: t("GEOFENCES.port_return")},
    { field: "port_category_id", headerName: t("GEOFENCES.port_category") },
    { field: "branch_id", headerName: t("GEOFENCES.branch")},
    { field: "updated_at", headerName: t("GEOFENCES.last_updated") },
  ])
  const [columns, setColumns] = useState([
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60, sortable: false },
    { field: "id", headerName: "#", minWidth: 50, maxWidth: 60, sortable: false },
    { field: "name", headerName: t("GEOFENCES.name"), minWidth: 110, maxWidth: 150, flex: 2, sortable: false },
    { field: "code", headerName: t("GEOFENCES.code"), minWidth: 110, maxWidth: 150, flex: 2, sortable: false },
    { field: "port_code", headerName: t("GEOFENCES.port_code"), minWidth: 110, maxWidth: 150, flex: 1, sortable: false },
    { field: "port_type_name", headerName: t("GEOFENCES.port_type"), minWidth: 110, maxWidth: 150, flex: 1, sortable: false },
    { field: "port_return_name", headerName: t("GEOFENCES.port_return"), minWidth: 110, maxWidth: 150, flex: 1, sortable: false },
    { field: "port_category_name", headerName: t("GEOFENCES.port_category"), minWidth: 110, maxWidth: 150, flex: 1, sortable: false },
    { field: "branch_name", headerName: t("GEOFENCES.branch"), minWidth: 110, maxWidth: 150, flex: 1, sortable: false },
    {
      field: "last_updated", headerName: t("GEOFENCES.last_updated"), minWidth: 110, maxWidth: 150, flex: 1, sortable: false,
      renderCell: (rows) => {


        return (
          <Typography
            sx={{
              fontFamily: 'Cairo'
            }}
          >
            {rows?.row?.formatted_updated_at ? rows?.row?.formatted_updated_at : rows?.row?.formatted_datetime}
          </Typography>
        )
      }
    },
    {
      field: "linked_to_wialon", headerName: t("GEOFENCES.linked_to_wialon"), minWidth: 50, maxWidth: 50, flex: 1, sortable: false,
      renderCell: (rows) => {

        if (rows?.row?.resource_id && rows?.row?.geofence_id) {
          return (
            <Box>
              <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("GEOFENCES.linked_to_wialon")}</span>}>
                <LinkIcon sx={{ fill: 'green' }} />
              </Tooltip>
            </Box>
          )
        }
        return (
          <Box>
            <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("GEOFENCES.not_linked_to_wialon")}</span>}>
              <LinkOffIcon sx={{ fill: 'red' }} />
            </Tooltip>
          </Box>
        )
      }
    },

    {
      headerName: t("GEOFENCES.actions"),
      field: "Actions",
      flex: 0.4,
      minWidth: 100,
      sortable: false,
      renderHeader: () => (
        <Typography>
          {rolesRedux?.update_geofence?.value ? t("GEOFENCES.actions") : null}
        </Typography>
      ),
      // hide: !rolesRedux?.update_geofence?.value,
      renderCell: (rows) => (
        rolesRedux?.update_geofence?.value || rolesRedux?.update_geofence_name?.value || rolesRedux?.update_geofence_code?.value || rolesRedux?.enable_geofence?.value ?
          <DropDownGrid>
            {rolesRedux?.update_geofence?.value ?
              <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  {rows?.row?.geofence_id ?
                    <LinkOffIcon style={{ fill: "#ff0000" }} /> :
                    <LinkIcon style={{ fill: "#17681b" }} />}


                </ListItemIcon>
                {rows?.row?.geofence_id ?
                  t('GEOFENCES.unlink') : t('GEOFENCES.link')}
              </MenuItem> : null}
            {rolesRedux?.enable_geofence?.value ?
              <MenuItem onClick={() => { updateEnableFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  {rows?.row?.enabled ?
                    <CancelIcon style={{ fill: "#ff0000" }} /> :
                    <CheckCircleIcon style={{ fill: "#17681b" }} />}


                </ListItemIcon>
                {rows?.row?.enabled ?
                  t('GEOFENCES.disable') : t('GEOFENCES.enable')}
              </MenuItem> : null}
            {rolesRedux?.update_geofence_name?.value ?
              <MenuItem onClick={() => { updateNameFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>

                  <EditIcon style={{ fill: "#17681b" }} />
                </ListItemIcon>
                {t('GEOFENCES.update_name')}
              </MenuItem> : null}
            {rolesRedux?.update_geofence_code?.value ?
              <MenuItem onClick={() => { updateCodeFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>

                  <EditIcon style={{ fill: "#17681b" }} />
                </ListItemIcon>
                {t('GEOFENCES.update_code')}
              </MenuItem> : null}

          </DropDownGrid> : null
      ),
    },
  ])
  


  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  const getResources = () => {


    branchAPI.getBranchList({
      params: {
        ...headerObject,
      }

    }).then(branchesResult => {
      if (branchesResult.status && branchesResult?.data?.data) {
        setBranches(branchesResult?.data?.data)
      }
      setLoadingBranches(false);
    }).catch(error => {
      console.log(error);
      setLoadingBranches(false);
    }).finally(() => {
      setLoadingBranches(false);
    })

    portTypeAPI.getPortTypeList({
      params: {
        ...headerObject,
      }

    }).then(portTypesResult => {
      if (portTypesResult.status && portTypesResult?.data?.data) {
        setPortTypes(portTypesResult?.data?.data)
      }
      setLoadingPortTypes(false);
    }).catch(error => {
      console.log(error);
      setLoadingPortTypes(false);
    }).finally(() => {
      setLoadingPortTypes(false);
    })

    portCategoryAPI.getPortCategoryList({
      params: {
        ...headerObject,
      }

    }).then(portCategoriesResult => {
      if (portCategoriesResult.status && portCategoriesResult?.data?.data) {
        setPortCategories(portCategoriesResult?.data?.data)
      }
      setLoadingPortCategories(false);
    }).catch(error => {
      console.log(error);
      setLoadingPortCategories(false);
    }).finally(() => {
      setLoadingPortCategories(false);
    })

    portReturnAPI.getPortReturnList({
      params: {
        ...headerObject,
      }

    }).then(portReturnsResult => {
      if (portReturnsResult.status && portReturnsResult?.data?.data) {
        setPortReturns(portReturnsResult?.data?.data)
      }
      setLoadingPortReturns(false);
    }).catch(error => {
      console.log(error);
      setLoadingPortReturns(false);
    }).finally(() => {
      setLoadingPortReturns(false);
    })



  }

  useEffect(() => {
    getResources()
    return () => {

    }
  }, [])
  useEffect(() => {
    loadData()
  }, [pageNumber, pageSize, reload]);

  const loadData = async (searchParams) => {
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let sortColumnsFiltered = sortColumns?.filter(column=>column?.sort)?.map(column=>{
        return {
          field:column?.field,
          asc:column?.asc
        }
      })
      console.log(sortColumns);
      
      let object = {
        ...headerObject,
        ...(filters?.export_excel ? {} :
          {
            page: pageNumber,
            page_size: pageSize,
          }),

        search: "",
        sort_columns: sortColumnsFiltered,
        ...searchParams,
        ...filters
      };

      setLoading(true);

      const result = await geofenceAPI.geofences({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);


      if (result.status && result?.data?.data) {


        if (filters?.export_excel) {
          setFilters({
            ...filters,
            export_excel: false
          })
          downloadExcel(result?.data?.data)
        }
        else {
          if (result?.data?.data?.data?.length > 0) {
            setRows(result?.data?.data?.data);
            setRowsTotal(result?.data?.data?.total_records);
            setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
          }
          else {
            setRows([]);
            setRowsTotal(0);
            setPageCount(0);
          }
        }
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };


  const createFunction = () => {
    setselectedObject(null);
    setOpenForm(true);
  };
  const updateFunction = (obj) => {
    if (obj?.geofence_id) {
      swal({
        title: t('GLOBAL.are_you_sure'),
        text: t('GEOFENCES.wialon_link_will_be_removed'),
        icon: 'warning',
        buttons: [t('GLOBAL.no'), t('GLOBAL.yes')],
        dangerMode: true,
      }).then(async (updateWithWialonLink) => {

        if (updateWithWialonLink) {
          unlinkGeofence(obj)
        } else {
          // alert('canceled')
        }
      })
    }
    else {
      setselectedObject(obj)
      setOpenForm(true)
    }

  }

  const updateNameFunction = (obj) => {
    setselectedObject(obj)
    setOpenUpdateNameForm(true)
  }
  const updateCodeFunction = (obj) => {
    setselectedObject(obj)
    setOpenUpdateCodeForm(true)
  }
  const unlinkGeofence = async (geofence) => {
    let readyToSendObject = {}
    if (geofence?.geofence_id) {

      readyToSendObject["resource_id"] = null;
      readyToSendObject["geofence_id"] = null;
      readyToSendObject["update_wialon_link"] = 1;
    }

    let params = {
      username: cookies.get("user_name"),
      user_id: cookies.get("user_id"),
      account_name: cookies.get("account_name"),
      account_id: cookies.get("account_id"),
      sid: cookies.get("sid"),
      baseurl: cookies.get("baseUrl"),
      id: cookies.get("id"),
    };

    let result = null;
    if (geofence && geofence?.id) {
      readyToSendObject["id"] = geofence?.id;

      try {
        result = await geofenceAPI.editGeofence({
          data: readyToSendObject,
          params: params,
          id: geofence?.id,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (result?.status && result?.data?.status) {



          toast.success(t("NETWORK_MESSAGES.infoUpdatedSuccessfully"));

          loadData();

        } else {


          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }

      } catch (error) {


        toast.error(error.message);
      }
    }
  }

  const updateEnableFunction = (obj) => {

    swal({
      title: t('GLOBAL.are_you_sure'),
      text: obj?.enabled ? t('GEOFENCES.goefence_will_be_disabled') : t('GEOFENCES.goefence_will_be_enabled'),
      icon: 'warning',
      buttons: [t('GLOBAL.no'), t('GLOBAL.yes')],
      dangerMode: true,
    }).then(async (updateWithWialonLink) => {

      if (updateWithWialonLink) {
        updateEnableGeofence(obj)
      } else {
        // alert('canceled')
      }
    })


  }
  const updateEnableGeofence = async (geofence) => {
    let readyToSendObject = {}
    readyToSendObject["enabled"] = geofence?.enabled == 1 ? 0 : 1;


    let params = {
      username: cookies.get("user_name"),
      user_id: cookies.get("user_id"),
      account_name: cookies.get("account_name"),
      account_id: cookies.get("account_id"),
      sid: cookies.get("sid"),
      baseurl: cookies.get("baseUrl"),
      id: cookies.get("id"),
    };

    let result = null;
    if (geofence && geofence?.id) {
      readyToSendObject["id"] = geofence?.id;

      try {
        result = await geofenceAPI.editGeofence({
          data: readyToSendObject,
          params: params,
          id: geofence?.id,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        if (result?.status && result?.data?.status) {



          toast.success(t("NETWORK_MESSAGES.infoUpdatedSuccessfully"));

          loadData();

        } else {


          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }

          else {

            toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
          }
        }

      } catch (error) {


        toast.error(error.message);
      }
    }
  }
  const clearFilterFunction = () => {
    setFilters({
      name: "",
      code: "",
      port_type_id: null,
      branch_id: null,
      port_category_id: null,
      port_return_id: null,

      port_type: null,
      branch: null,
      port_category: null,
      port_return: null,
      port_code: "",
      wialon_id: null,
      export_excel: false
    })
  }
  const searchFunction = () => {
    setReload(!reload)
    setPageNumber(1)
  };

  const importFunction = () => {
    setOpenImportForm(true)
  }
  const exportFunction = () => {

    setFilters({
      ...filters,
      export_excel: true
    })
    setReload(!reload)
  }
  const downloadExcel = async (fileName) => {
    const result = await geofenceAPI?.downloadExcel({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }

  const getRowClassName = (params) => {
    if (params?.row?.enabled === 0) {
      return 'highlighted-disabled-row'
    }
    return ''
  };
  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Medium",
        },
      }}
    >
      {openImportForm ? (
        <ImportFormContainer
          object={selectedObject}
          openForm={openImportForm}
          setOpenForm={setOpenImportForm}
          loadData={() => { }}
        />
      ) : null}
      {openUpdateNameForm ? (
        <UpdateNameFormContainer
          object={selectedObject}
          openForm={openUpdateNameForm}
          setOpenForm={setOpenUpdateNameForm}
          loadData={loadData}
        />
      ) : null}
      {openUpdateCodeForm ? (
        <UpdateCodeFormContainer
          object={selectedObject}
          openForm={openUpdateCodeForm}
          setOpenForm={setOpenUpdateCodeForm}
          loadData={loadData}
          loadingBranches={loadingBranches}
          loadingPortCategories={loadingPortCategories}
          loadingPortReturns={loadingPortReturns}
          loadingPortTypes={loadingPortTypes}
          branches={branches}
          portCategories={portCategories}
          portReturns={portReturns}
          portTypes={portTypes}
        />
      ) : null}
      {openForm ? (
        <FormContainer
          object={selectedObject}
          openForm={openForm}
          setOpenForm={setOpenForm}
          loadData={loadData}
          loadingBranches={loadingBranches}
          loadingPortCategories={loadingPortCategories}
          loadingPortReturns={loadingPortReturns}
          loadingPortTypes={loadingPortTypes}
          branches={branches}
          portCategories={portCategories}
          portReturns={portReturns}
          portTypes={portTypes}
        />
      ) : null}

      <Title
        createFunction={createFunction}
        openFilterColumn={openFilterColumn}
      />
      <Grid
        rows={rows}
        hasColumnsHidShow={true}
        hasColumnsSorting={true}
        hasExport={rolesRedux?.export_geofence?.value}
        hasImport={rolesRedux?.add_geofence?.value}
        importFunction={importFunction}
        exportFunction={exportFunction}
        getRowClassName={getRowClassName}
        columns={columns}
        setColumns={setColumns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        onSortClick={()=>{setReload(!reload)}}
        setSortColumns={setSortColumns}
        sortColumns={sortColumns}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}


        filterChilds={
          <Search
            filters={filters}
            setFilters={setFilters}
            loadingBranches={loadingBranches}
            loadingPortCategories={loadingPortCategories}
            loadingPortReturns={loadingPortReturns}
            loadingPortTypes={loadingPortTypes}
            branches={branches}
            portCategories={portCategories}
            portReturns={portReturns}
            portTypes={portTypes}
          />
        }
      />

    </Box>
  );
}
