import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Checkbox, Grid, ListItemText } from "@mui/material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Fade from "@mui/material/Fade";
import { json } from "react-router";
import Cookies from "universal-cookie";
import PopupForm from "../../Components/PopupForm";
import CustomList from "../../Components/CustomList";
const cookies = new Cookies()


export default function SortColumn(props) {

  const [t] = useTranslation("common");

  const dataGridName = window.location.href
  const handleClose = () => {
    props?.setOpen(false);

  };
 
 
  

  return (
    <PopupForm
      // open={props?.openForm}
      setOpen={props?.setOpen}
      open={props?.open}
      onClose={handleClose}
      title={t("GLOBAL.sort_columns")}
      isFullScreen={false}

      content={<Box className="Container-fluid position-relative" sx={{ flexGrow: 1 }}>
        <CustomList columns={props?.columns?props?.columns:[]} setColumns={props?.setColumns}/>
      </Box>

      }
      footer={
        <>


          <Box sx={{ height: '100px', display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
            <Box display="flex" >

              
              <Button
                variant="contained"
                // spacing={2} 
                sx={{
                  // flexGrow: 1,
                  margin: 1,
                  width: '180px !important',
                  minWidth: '180px !important',
                  maxWidth: '180px !important',
                  backgroundColor: '#e7a52a',
                  '&:hover': {
                    backgroundColor: '#e7a52a' + '88',
                  },
                  height: '35px',
                  fontFamily: 'Cairo-Bold'
                }}
                className="iconeFilterSearch"
                onClick={() => {
                 
                  props?.onSortClick()
                  props?.setOpen(false)
                }}
              >
                {t('GLOBAL.confirm')}
              </Button>
            </Box>
          </Box>

        </>

      }
    />
  );
}
