import { Autocomplete, Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import SearchInput from '../../Components/SearchInput';
import unitAPI from '../../Network/Unit';
import geofenceAPI from '../../Network/Geofence';
import routeAPI from '../../Network/Route';
import Cookies from "universal-cookie";
import moment from 'moment/moment';
import CustomDateRange from '../../Components/CustomDateRange';
const cookies = new Cookies();

const SearchShort = ({
  filters,
  setFilters,
  loadingBranches,
  branches,
  portCategories,
  loadingPortCategories,
  loadingTransactionStatuses,
  loadingUsers,
  users,
  transactionStatuses,
  transactionType2List,
  loadingTransactionType2List,
  noUnits,
  showType,
  showUnitTransactionType,
  loadingTransactionProductTypes,
  transactionProductTypes,
}) => {
  const [t] = useTranslation("common");
  const timerUnits = useRef(null)
  const timerSourceGeofences = useRef(null)
  const timerDestinationGeofences = useRef(null)
  const timerRoutes = useRef(null)

  const [units, setUnits] = useState([])
  const [sourceGeofences, setSourceGeofences] = useState([])
  const [destinationGeofences, setDestinationGeofences] = useState([])
  const [routes, setRoutes] = useState([])

  const [loadingUnits, setLoadingUnits] = useState(false)
  const [loadingSourceGeofences, setLoadingSourceGeofences] = useState(false)
  const [loadingDestinationGeofences, setLoadingDestinationGeofences] = useState(false)
  const [loadingRoutes, setLoadingRoutes] = useState(false)


  const [searchTermUnit, setSearchTermUnit] = useState("");
  const [searchTermSourceGeofence, setSearchTermSourceGeofence] = useState("");
  const [searchTermDestinationGeofence, setSearchTermDestinationGeofence] = useState("");
  const [searchTermRoute, setSearchTermRoute] = useState("");

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  const handleSearchTermUnitChange = (newValue) => {


    setSearchTermUnit(newValue);
    if (timerUnits.current) {
      clearTimeout(timerUnits?.current)
    }
    timerUnits.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingUnits(true);

        const result = await unitAPI.getUnitList({
          params: {
            ...headerObject,
            name: newValue,
            page_size: 20,
            page: 1
          }

        })
        setLoadingUnits(false);

        if (result.status && result?.data?.data?.data) {
          setUnits(result?.data?.data?.data)
        }
      }
      else {
        setUnits([])
      }
    }, 1000)
  };

  const handleSearchTermSourceGeofenceChange = (newValue) => {

    setSearchTermSourceGeofence(newValue);
    if (timerSourceGeofences.current) {
      clearTimeout(timerSourceGeofences?.current)
    }
    timerSourceGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingSourceGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [1, 3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingSourceGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setSourceGeofences(result?.data?.data?.data)
        }
      }
      else {
        setSourceGeofences([])
      }
    }, 1000)
  };
  const handleSearchTermDestinationGeofenceChange = (newValue) => {

    setSearchTermDestinationGeofence(newValue);
    if (timerDestinationGeofences.current) {
      clearTimeout(timerDestinationGeofences?.current)
    }
    timerDestinationGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingDestinationGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [2, 3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingDestinationGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setDestinationGeofences(result?.data?.data?.data)
        }
      }
      else {
        setDestinationGeofences([])
      }
    }, 1000)
  };

  const handleSearchTermRouteChange = (newValue) => {

    setSearchTermRoute(newValue);
    if (timerRoutes.current) {
      clearTimeout(timerRoutes?.current)
    }
    timerRoutes.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingRoutes(true);

        const result = await routeAPI.getRouteList({
          params: {
            ...headerObject,
            name: newValue,
            page_size: 20,
            page: 1
          }

        })
        setLoadingRoutes(false);

        if (result.status && result?.data?.data?.data) {
          setRoutes(result?.data?.data?.data)
        }
      }
      else {
        setRoutes([])
      }
    }, 1000)
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >

      <SearchInput
        value={filters?.transaction_number}
        setValue={(value) => {

          setFilters({
            ...filters,
            transaction_number: value
          })


        }}
        title={t('TRANSACTIONS.transaction_number')}
      />

      <SearchInput
        value={filters?.unit_registration_number}
        setValue={(value) => {
          setFilters({
            ...filters,
            unit_registration_number: value
          })
        }}
        title={t('TRANSACTIONS.unit_registration_number')}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TRANSACTIONS.branches_general')}
          </Typography>
          <Autocomplete
            sx={{
              ml: 1.5, mt: 1, mb: 1, width: '95%',
              '& .MuiFormLabel-root,& .MuiInputBase-input': {
                fontFamily: 'Cairo-Medium'
              }
            }}
            value={filters?.branches_selected ? filters?.branches_selected : []}
            options={branches?.length ? branches : []}
            multiple={true}
            loading={loadingBranches}
            loadingText={t('GLOBAL.searching')}
            getOptionLabel={(option) => option && option?.name || ""}

            onChange={(e, v, r) => {
              setFilters({
                ...filters,
                branch_ids: v?.length > 0 ? v?.map(branch => branch?.id) : [],
                branches_selected: v
              })
            }}
            renderInput={(params) => (
              <TextField {...params}
                placeholder={t('TRANSACTIONS.branches_general')}
                variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
            )}
          />

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.branch_type}
            onChange={(e) => {
              setFilters({
                ...filters,
                branch_type: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.source_branch")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.destination_branch")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>




      <CustomDateRange
        dateRange={filters}
        setDateRange={(value) => {
          setFilters({
            ...filters,
            ...value
          })
        }}
      />


      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}

        loading={loadingTransactionProductTypes}
        loadingText={t('GLOBAL.searching')}
        value={filters?.productType}
        options={transactionProductTypes}
        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}
        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            productType: v,
            product_type_id: v ? v?.id : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.product_type')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />


      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        onInputChange={(event, newInputValue) => {

          handleSearchTermSourceGeofenceChange(newInputValue);
        }}
        inputValue={searchTermSourceGeofence}
        loading={loadingSourceGeofences}
        loadingText={t('GLOBAL.searching')}
        value={filters?.sourceGeofence}
        options={sourceGeofences?.length ? sourceGeofences : []}
        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}
        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            sourceGeofence: v,
            source_geofence_id: v ? v?.id : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.source_geofence')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        onInputChange={(event, newInputValue) => {

          handleSearchTermDestinationGeofenceChange(newInputValue);
        }}
        inputValue={searchTermDestinationGeofence}
        loading={loadingDestinationGeofences}
        loadingText={t('GLOBAL.searching')}
        value={filters?.destinationGeofence}
        options={destinationGeofences?.length ? destinationGeofences : []}

        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}



        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            destinationGeofence: v,
            destination_geofence_id: v ? v?.id : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.destination_geofence')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TRANSACTIONS.carrier')}
          </Typography>

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.carrier}
            onChange={(e) => {
              setFilters({
                ...filters,
                carrier: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.carrier_governmental")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.carrier_private")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>


    </Box>
  );
};

export default SearchShort