
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useTranslation } from "react-i18next";
import MapIcon from '@mui/icons-material/Map';
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import TableRowsIcon from "@mui/icons-material/TableRows";
import SortIcon from '@mui/icons-material/Sort';
import TelegramIcon from "@mui/icons-material/Telegram";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Drawer, IconButton, Tooltip } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import SpeedDialTooltipOpen from './SpeedDialTooltipOpen';


const StyledSpeedDial = styled(SpeedDial)(({ theme, gridtheme }) => ({
  // position: 'relative',
  position: 'relative',
  // top:0,
  // left:'50px',
  // zIndex:3000,
  // display:'block',
  width: '50px',
  height: '36px',
  '& .MuiFab-root-MuiSpeedDial-fab': {
    width: 40,
    height: 40,
    backgroundColor: `${gridtheme?.primaryColor}44 !important`,


  },
  '& .MuiFab-root-MuiSpeedDial-fab:hover': {
    backgroundColor: `#e7a52a !important`,
  },
  backgroundColor: `${gridtheme?.primaryColor}44 !important`,
  padding: 0,
  // '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
  //   bottom: 0,
  //   right:0,
  // },
  // '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
  //   top: 0,
  //   left: 0,
  //   backgroundColor:`${gridtheme?.primaryColor}44 !important`,
  // },
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    // bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    // top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

export default function CustomeFilter(props) {
  const { t, i18n } = useTranslation('common');
  const sideMenuMode = useSelector(state => state.settingsData.sideMenuMode)
  const SearchButtonTheme = useSelector(state => state.settingsData.SearchButtonTheme);
  const toastId = React.useRef(null);
  const FilterButtonHeight = useSelector(state => state.settingsData.FilterButtonHeight)
  const directions = useSelector(state => state.settingsData.directions)
  const gridtheme = useSelector(state => state.themeData.gridtheme)
  const maintheme = useSelector(state => state.themeData.maintheme)
  const [actions, setActions] = useState([])
  const [hidden, setHidden] = React.useState(false);
  // const {search_button_color,clear_button_color,search_button_text_color,clear_button_text_color} =SearchButtonTheme

  const divRef = useRef(null);


  const [hideSearch, setHideSearch] = useState(true)
  useEffect(() => {
    const emptyArray = [];
    // if (props?.hasExport) {
    //   emptyArray.push({ icon: <FileDownloadIcon onClick={props?.exportFunction} sx={{ color: gridtheme?.tooltip_export_text + " !important" }} />, color: gridtheme?.tooltip_export, name: t('TOOLTIP.EXPORT') })
    // }
    if (props?.hasCreate) {
      emptyArray.push({ icon: <AddIcon onClick={props?.createFunction} sx={{ color: '#17681b' + " !important" }} />, color: gridtheme?.tooltip_create, name: t('TOOLTIP.CREATE') })
    }
    if (props?.hasMaps) {
      emptyArray.push({ icon: <MapIcon onClick={props?.mapFunction} sx={{ color: gridtheme?.tooltip_map_text + " !important" }} />, color: gridtheme?.tooltip_map, name: t('TOOLTIP.MAP') })
    }
    // if (props?.hasImport) {
    //   emptyArray.push({ icon: <FileUploadIcon onClick={props?.importFunction} sx={{ color: gridtheme?.tooltip_import_text + " !important" }} />, color: gridtheme?.tooltip_import, name: t('TOOLTIP.IMPORT') })
    // }
    if (props?.hasTelegram) {
      emptyArray.push({ icon: <TelegramIcon onClick={props?.telegramFunction} sx={{ color: gridtheme?.tooltip_telegram_text + " !important" }} />, color: gridtheme?.tooltip_telegram, name: t('TOOLTIP.SEND_TELEGRAM') })
    }
    if (props?.hasView) {
      emptyArray.push({ icon: <RemoveRedEyeIcon onClick={props?.viewFunction} sx={{ color: gridtheme?.tooltip_view_text + " !important" }} />, color: gridtheme?.tooltip_view, name: t('TOOLTIP.VIEW') })
    }
    setActions(emptyArray);
  }, [props, SearchButtonTheme])




  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      // setHideSearch(true);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box
      onKeyDown={(event) => {


        if (event.key === 'Enter') {
          props?.searchFunction()
          setHideSearch(true)
          event.preventDefault();
        }

      }}
    >
      <Box

        sx={{
          position: 'absolute',
          top: '5px',
          // left: props?.filterHasSelectCounter ? '330px !important' : "250px !important",
          right: hideSearch ? '-25px !important' : '225px !important',
          width: 'fit-content',

          height: '30px',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: `${gridtheme?.lightblack} !important`,

        }}
      >
        <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("GLOBAL.refresh")}</span>}>
          <IconButton
            sx={{
              padding: "2px",
              marginRight: '10px',
              backgroundColor: gridtheme?.gridHeader,
              ":hover": {
                backgroundColor: gridtheme?.gridHeader + "AA",
              }
            }}
            onClick={() => {
              props?.searchFunction()
            }}
          >
            {hideSearch ?
              <CachedIcon sx={{
                fontSize: "40px",
                color: gridtheme?.colorWhite
              }} />
              :
              null
              // <ArrowBackIosNewIcon sx={{
              //   fontSize: "40px",
              //   color: gridtheme?.colorWhite
              // }} />
            }
          </IconButton>
        </Tooltip>
        {
          props?.hasColumnsHidShow ?
            <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("GLOBAL.show_hide_columns")}</span>}>
              <IconButton

                style={{
                  padding: "7px",
                  marginLeft: '10px',
                  backgroundColor: gridtheme?.gridHeader,
                  ":hover": {
                    backgroundColor: gridtheme?.gridHeader + "AA",
                  }
                }}

                onClick={() => {
                  props?.setOpenFilterColumn(!props?.openFilterColumn);
                }}
              >
                <TableRowsIcon style={{
                  fontSize: "30px",
                  color: gridtheme?.colorWhite
                }} />
              </IconButton>
            </Tooltip> : null

        }
        {
          props?.hasColumnsSorting ?
            <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("GLOBAL.sort_columns")}</span>}>
              <IconButton

                style={{
                  padding: "7px",
                  marginLeft: '10px',
                  backgroundColor: gridtheme?.gridHeader,
                  ":hover": {
                    backgroundColor: gridtheme?.gridHeader + "AA",
                  }
                }}

                onClick={() => {
                  props?.setOpenSortColumn(!props?.openSortColumn);
                }}
              >
                <SortIcon style={{
                  fontSize: "30px",
                  color: gridtheme?.colorWhite
                }} />
              </IconButton>
            </Tooltip> : null

        }
        {props?.hasExport ?
          <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("GLOBAL.export_data")}</span>}>
            <IconButton
              sx={{
                padding: "2px",
                marginRight: '10px',
                backgroundColor: gridtheme?.gridHeader,
                ":hover": {
                  backgroundColor: gridtheme?.gridHeader + "AA",
                }
              }}
              onClick={() => {
                props?.exportFunction()
              }}
            >
              <FileDownloadIcon
                sx={{
                  fontSize: "40px",
                  color: gridtheme?.colorWhite
                }}
              />
            </IconButton>
          </Tooltip>
          : null}
        {props?.multipleExports?.length ?
          <SpeedDialTooltipOpen
            label={t("GLOBAL.show_hide_columns")}
            icon={<FileDownloadIcon
              sx={{
                fontSize: "40px",
                color: gridtheme?.colorWhite
              }}
            />}
            actions={props?.multipleExports}
          />

          : null}
        {props?.hasImport ?
          <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("GLOBAL.import_data")}</span>}>
            <IconButton
              sx={{
                padding: "2px",
                marginRight: '10px',
                backgroundColor: gridtheme?.gridHeader,
                ":hover": {
                  backgroundColor: gridtheme?.gridHeader + "AA",
                }
              }}
              onClick={() => {
                props?.importFunction()
              }}

            >
              <FileUploadIcon
                sx={{
                  fontSize: "40px",
                  color: gridtheme?.colorWhite
                }}
              />
            </IconButton>
          </Tooltip>
          : null}
        {props?.dosntHaveSearch?null:
        <Tooltip title={<span style={{ fontFamily: "Cairo" }}>{t("GLOBAL.show_hide_search")}</span>}>
          <IconButton
            sx={{
              padding: 0,
              borderRadius: 0,
              borderTopLeftRadius: '5px',
              borderBottomLeftRadius: '5px',
              backgroundColor: gridtheme?.gridHeader,
              ":hover": {
                backgroundColor: gridtheme?.gridHeader + "AA",
              }
            }}
            onClick={() => {
              setHideSearch(!hideSearch)
            }}
          >
            {hideSearch ?
              <SearchIcon sx={{
                fontSize: "40px",
                color: gridtheme?.colorWhite
              }} />
              :
              null
              // <ArrowBackIosNewIcon sx={{
              //   fontSize: "40px",
              //   color: gridtheme?.colorWhite
              // }} />
            }
          </IconButton>
        </Tooltip>}

      </Box>

      <Drawer
        anchor={"right"}
        open={!hideSearch}
        onClose={() => setHideSearch(true)}
      >

        <Box
          sx={{
            width: props?.filterHasSelectCounter ? '330px !important' : "250px !important",
            minWidth: props?.filterHasSelectCounter ? '330px !important' : "250px !important",
            maxWidth: props?.filterHasSelectCounter ? '330px !important' : "250px !important",


            height: 'calc(100vh - 60px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: gridtheme?.paperColor,
            boxShadow: ` 0px 2px 7px -4px rgb(0 0 0 / 64%)`,
            borderRadius: "5px",
            margin: "0px 10px 0 10px",
            // paddingTop:'10px',
            // paddingBottom:'20px',

            // padding: "0 10px",
            '& p , & span ,& .MuiInputLabel-root': {
              fontSize: "15px !important"
            },


            padding: '0 !important',
            "& .MuiSpeedDial-fab":
            {
              width: "37px !important",
              height: "35px !important",
              minHeight: "35px !important",
              minWidth: "37px !important",
              border: "none !important",
              outline: "none !important",
            },
            position: 'fixed!important',
            top: '60px !important',
            right: '0px',
            bottom: '0!important',
            /* height:100% !important; */
            width: '350px !important',
            borderRadius: '0!important',

            // transition: 'transition: left 0.3s ease  !important',

            // visibility: hideSearch ? 'hidden' : 'visible'
          }}

        >

          <Box
            sx={{
              display: "flex",
              width: "100% !important",
              position: 'relative',
              backgroundColor: gridtheme?.gridHeader,
              height: '70px',
              alignItems: 'center',
              justifyContent: 'space-between',
              overflow: 'hidden !important',
              zIndex: '1000000 !important'

            }}
          >

            {
              !props?.filterHasSelectCounter ?
                <Box
                  sx={{



                  }}>
                  <StyledSpeedDial
                    ariaLabel="SpeedDial playground example"
                    hidden={hidden}
                    icon={<MoreVertIcon sx={{ color: gridtheme?.lightblack }} />}
                    direction='right'
                    sx={{
                      '.MuiSpeedDial-fab': {
                        backgroundColor: 'white'
                      },
                      '.MuiSpeedDial-fab:hover': {
                        backgroundColor: '#e7a52a'
                      },
                      '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(1)': {
                        // backgroundColor:'green !important',
                        color: '#ffffff',
                        position: 'relative',
                        // top:'-15px',
                        left: '-20px',
                        width: "37px !important",
                        height: "35px !important",
                        minHeight: '35px',
                        minWidth: '37px',

                      },
                      '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(2)': {
                        // backgroundColor:'blue !important',
                        color: '#ffffff',
                        position: 'relative',
                        // top:'-25px',
                        left: '-30px',
                        width: "37px !important",
                        height: "35px !important",
                        minHeight: '35px',
                        minWidth: '37px',
                        backgroundColor: '#fff'

                      },
                      '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(3)': {
                        // backgroundColor:'#c40c3d !important',
                        color: '#ffffff',
                        position: 'relative',
                        // top:'-30px',
                        left: '-35px',
                        width: "37px !important",
                        height: "35px !important",
                        minHeight: '35px',
                        minWidth: '37px',
                      },
                      '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(4)': {
                        // backgroundColor:'orange !important',
                        color: '#ffffff',
                        position: 'relative',
                        // top:'-35px',
                        left: '-40px',
                        width: "37px !important",
                        height: "35px !important",
                        minHeight: '35px',
                        minWidth: '37px',

                      }
                    }}

                  >
                    {actions.map((action) => (
                      <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={<span style={{ fontFamily: 'Cairo-Medium' }}>{action.name}</span>}
                        sx={{
                          backgroundColor: action?.color ? action?.color : '#00f',
                          '&:hover': {
                            backgroundColor: action?.color ? action?.color : '#0000ff',
                          },
                        }}
                      />
                    ))}
                  </StyledSpeedDial>
                </Box> : null
            }
            {
              props?.filterHasSelectCounter ?
                <Box
                  sx={{
                    position: 'absolute',
                    // top:'20px',
                    left: '10px !important',
                    width: 'fit-content',
                    height: '30px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: gridtheme?.colorWhite,
                    color: `${gridtheme?.lightblack} !important`,
                    padding: '2px 10px',
                  }}
                >
                  <Typography
                    variant='p'
                    sx={{
                      fontSize: '13px !important',
                      color: `${gridtheme?.lightblack} !important`,
                      fontFamily: 'Cairo-Bold',
                    }}
                  >
                    {t('GLOBAL.SEARCH_RESULT_OF_selected')}  {props?.filterHasSelectCounterValue}
                  </Typography>
                </Box> : null
            }

            <Box
              sx={{
                position: 'absolute',
                // top:'20px',
                right: '10px !important',
                width: 'fit-content',
                height: '30px',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: gridtheme?.colorWhite,
                color: `${gridtheme?.lightblack} !important`,
                padding: '2px 10px',
              }}
            >
              <Typography
                variant='p'
                sx={{
                  fontSize: '13px !important',
                  color: `${gridtheme?.lightblack} !important`,
                  fontFamily: 'Cairo-Bold',
                }}
              >
                {t('GLOBAL.SEARCH_RESULT_OF_SEARCH')}  {props?.resultLength}
              </Typography>
            </Box>

          </Box>
          <Box
            sx={{
              width: '100% !important',
              flexGrow: 1,
              overflowY: 'scroll',
              overflowX: 'hidden',
              // overflowX:'hidden',
              // padding:'10px',
              margin: 'auto',
              marginTop: '10px',
              // paddingTop:'20px',
              padding: '0 10px 0 0px',
              zIndex: '1061!important',
              // boxShadow:'0 0 7px -5px #000 inset'
            }}
          >

            {props?.children}
          </Box>
          <Box sx={{ height: '100px', display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
            <Box display="flex" >

              <Button variant="outlined"
                // spacing={2}
                sx={{
                  width: '50% !important',
                  // flexGrow: 1, 
                  minWidth: '80px !important',
                  maxWidth: '80px !important',
                  margin: 1,
                  // backgroundColor:SearchButtonTheme?.clear_button_color,
                  color: "#e7a52a",
                  '&:hover': {
                    backgroundColor: `#e7a52a88   !important`,
                  },
                  height: '35px',
                  fontFamily: 'Cairo-Bold'

                }}
                className="iconeFilterClear"
                color="secondary"
                onClick={() => props.clearFunction()}
              >
                {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
              </Button>
              <Button
                variant="contained"
                // spacing={2} 
                sx={{
                  // flexGrow: 1,
                  margin: 1,
                  width: '80px !important',
                  minWidth: '80px !important',
                  maxWidth: '80px !important',
                  backgroundColor: '#e7a52a',
                  '&:hover': {
                    backgroundColor: '#e7a52a' + '88',
                  },
                  height: '35px',
                  fontFamily: 'Cairo-Bold'
                }}
                className="iconeFilterSearch"
                onClick={() => {
                  setHideSearch(true)
                  props.searchFunction()
                }}
              >
                {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
              </Button>
            </Box>
          </Box>
        </Box >
      </Drawer>
    </Box>

  );
}
