import { Autocomplete, Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import helpers from '../../assets/js/helper';
import SearchInput from '../../Components/SearchInput';
import CustomDateRange from '../../Components/CustomDateRange';
import Cookies from "universal-cookie";
import SearchRadio from '../../Components/SearchRadio';
const cookies = new Cookies();
const Search = ({
  filters,
  setFilters,
  loadingPlateProvinces,
  loadingUsers,
  loadingPlateTypes,
  loadingPlateLetters,
  plateProvinces,
  plateLetters,
  plateTypes,
  users
}) => {
  const [t] = useTranslation("common");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      {/* <SearchInput
        value={filters?.name}
        setValue={(value) => {
          setFilters({
            ...filters,
            name: value
          })
        }}
        title={t('UNITS.name')}
      /> */}
      <SearchInput
        value={filters?.plate_number}
        setValue={(value) => {
          setFilters({
            ...filters,
            plate_number: value
          })
        }}
        title={t('UNITS.plate_number')}
      />
      <SearchInput
        value={filters?.receipt_number}
        setValue={(value) => {
          setFilters({
            ...filters,
            receipt_number: value
          })
        }}
        title={t('UNITS.receipt_number')}
      />
      {cookies.get('id') == 46 ?
        <SearchInput
          value={filters?.tid}
          setValue={(value) => {
            setFilters({
              ...filters,
              tid: value
            })
          }}
          title={t('UNITS.tid')}
        /> : null}
      <SearchInput
        value={filters?.epc}
        setValue={(value) => {
          setFilters({
            ...filters,
            epc: value
          })
        }}
        title={t('UNITS.epc')}
      />
      <SearchInput
        value={filters?.company_name}
        setValue={(value) => {
          setFilters({
            ...filters,
            company_name: value
          })
        }}
        title={t('UNITS.company_name')}
      />
      <SearchInput
        value={filters?.vin_number}
        setValue={(value) => {
          setFilters({
            ...filters,
            vin_number: value
          })
        }}
        title={t('UNITS.vin_number')}
      />
      {cookies.get('id') == 46 ?
      <SearchInput
        value={filters?.less_than_days_left}
        setValue={(value) => {
          setFilters({
            ...filters,
            less_than_days_left: value
          })
        }}
        title={t('UNITS.less_than')}
      /> : null}
      {/* <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        multiple={true}
        value={filters?.plate_types_selected ? filters?.plate_types_selected : []}
        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            plate_type_ids: v?.length > 0 ? v?.map(object => object?.id) : [],
            plate_types_selected: v
          })
        }}

        options={plateTypes}
        loading={loadingPlateTypes}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.plate_type')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        multiple={true}
        value={filters?.plate_provinces_selected ? filters?.plate_provinces_selected : []}
        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            plate_province_ids: v?.length > 0 ? v?.map(object => object?.id) : [],
            plate_provinces_selected: v
          })
        }}

        options={plateProvinces}
        loading={loadingPlateProvinces}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.plate_province')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        multiple={true}
        value={filters?.plate_letters_selected ? filters?.plate_letters_selected : []}
        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            plate_letter_ids: v?.length > 0 ? v?.map(object => object?.id) : [],
            plate_letters_selected: v
          })
        }}
        options={plateLetters}
        loading={loadingPlateLetters}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}


        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('UNITS.plate_letter')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

       */}
      {cookies.get('id') == 46 ?
        <Box
          sx={{
            display: "flex",

            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >

          <Box
            sx={{
              backgroundColor: "lightgray",
              display: "flex",
              flexDirection: 'column',
              alignItems: "center",
              justifyContent: "start",
              ml: 1.5,
              mt: 1,
              mb: 1,
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            className="px-2 rounded"
          >
            <Typography>
              {t('UNITS.has_tid')}
            </Typography>

            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{
                width: "100%",
                "& .MuiTypography-root": {
                  fontFamily: "Cairo",
                },
              }}
              value={filters?.has_tid}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  has_tid: e?.target.value
                })

              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("UNITS.has_tid")}
              />
              <FormControlLabel
                value={0}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("UNITS.dosent_have_tid")}
              />
              <FormControlLabel
                value={-1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("UNITS.all")}
              />


            </RadioGroup>
          </Box>
        </Box> : null}
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('UNITS.has_epc')}
          </Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.has_epc}
            onChange={(e) => {
              setFilters({
                ...filters,
                has_epc: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.has_epc")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.dosent_have_epc")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('UNITS.is_subscribed')}
          </Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.is_subscribed}
            onChange={(e) => {
              setFilters({
                ...filters,
                is_subscribed: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.subscribed")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.not_subscribed")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('UNITS.is_installed')}
          </Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.is_installed_custom}
            onChange={(e) => {
              let searchString = null
              if (e?.target.value == 2) {
                searchString = "يوجد اكثر من مركب"
              }
              else if (e?.target.value == 3) {
                searchString = "ايوجد سلوت مطابق"
              }
              else if (e?.target.value == 4) {
                searchString = "ايوجد رقم مطابق"
              }
              setFilters({
                ...filters,
                is_installed: e?.target.value == 2 ? 0 : e?.target.value,
                is_installed_custom: e?.target.value,
                update_note: searchString
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.installed")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.not_installed")}
            />
            <FormControlLabel
              value={2}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.not_installed_duplicate")}
            />
            <FormControlLabel
              value={3}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.not_installed_slot")}
            />
            <FormControlLabel
              value={4}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.not_installed_plate")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('UNITS.has_duplicate')}
          </Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.has_duplicate}
            onChange={(e) => {
              setFilters({
                ...filters,
                has_duplicate: e?.target.value,
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.has_duplicate")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.doesnt_have_duplicate")}
            />

            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>
      <SearchRadio
        title={t('UNITS.has_wialon_duplicate')}
        value={filters?.has_wialon_duplicate}
        onChange={(e) => {
          setFilters({
            ...filters,
            has_wialon_duplicate: e?.target.value,
          })
        }}
        options={[
          {
            value: 1,
            label: t("UNITS.has_wialon_duplicate")
          },
          {
            value: 0,
            label: t("UNITS.doesnt_have_wialon_duplicate")
          },
          {
            value: -1,
            label: t("UNITS.all")
          }
        ]}
      />
      <SearchRadio
        title={t('UNITS.active')}
        value={filters?.active}
        onChange={(e) => {
          setFilters({
            ...filters,
            active: e?.target.value,
          })
        }}
        options={[
          {
            value: 1,
            label: t("UNITS.active")
          },
          {
            value: 0,
            label: t("UNITS.deactive")
          },
          {
            value: -1,
            label: t("UNITS.all")
          }
        ]}
      />
      <SearchRadio
        title={t('UNITS.unlink')}
        value={filters?.unlink}
        onChange={(e) => {
          setFilters({
            ...filters,
            unlink: e?.target.value,
          })
        }}
        options={[
          {
            value: 1,
            label: t("UNITS.unlink")
          },
          {
            value: 0,
            label: t("UNITS.not_unlink")
          },
          {
            value: -1,
            label: t("UNITS.all")
          }
        ]}
      />
      <CustomDateRange
        title={t('UNITS.installation_date')}
      
        dateRange={filters}
        setDateRange={(value) => {
          setFilters({
            ...filters,
            ...value
          })
        }}
      />
      <CustomDateRange
        title={t('UNITS.receipt_date')}
        dateRange={{
          creationDateFrom: filters?.receiptDateFrom,
          creation_date_from: filters?.receipt_date_from,
          creationDateTo: filters?.receiptDateTo,
          creation_date_to: filters?.receipt_date_to,
          all_time: filters?.all_time_receipt_date
        }}
        setDateRange={(value) => {
          setFilters({
            ...filters,
            receiptDateFrom: value?.creationDateFrom,
            receipt_date_from: value?.creation_date_from,
            receiptDateTo: value?.creationDateTo,
            receipt_date_to: value?.creation_date_to,
            all_time_receipt_date: value?.all_time

          })
        }}
      />


    </Box>
  );
};

export default Search