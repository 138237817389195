import {  Box } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import SearchInput from '../../Components/SearchInput';
import CustomDateRange from '../../Components/CustomDateRange';
import Cookies from "universal-cookie";

const Search = ({
  filters,
  setFilters,

}) => {
  const [t] = useTranslation("common");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      
      
      <SearchInput
        value={filters?.receipt_number}
        setValue={(value) => {
          setFilters({
            ...filters,
            receipt_number: value
          })
        }}
        title={t('UNITS.receipt_number')}
      />
      
      <CustomDateRange
        title={t('UNITS.receipt_date')}
        dateRange={{
          creationDateFrom: filters?.receiptDateFrom,
          creation_date_from: filters?.receipt_date_from,
          creationDateTo: filters?.receiptDateTo,
          creation_date_to: filters?.receipt_date_to,
          all_time: filters?.all_time_receipt_date
        }}
        setDateRange={(value) => {
          setFilters({
            ...filters,
            receiptDateFrom: value?.creationDateFrom,
            receipt_date_from: value?.creation_date_from,
            receiptDateTo: value?.creationDateTo,
            receipt_date_to: value?.creation_date_to,
            all_time_receipt_date: value?.all_time

          })
        }}
      />


    </Box>
  );
};

export default Search